// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBSd08_78AOdWsjYgKul1itha2JnjxA0BQ",
  authDomain: "moncongo-86c6c.firebaseapp.com",
  databaseURL: "https://moncongo-86c6c-default-rtdb.firebaseio.com",
  projectId: "moncongo-86c6c",
  storageBucket: "moncongo-86c6c.appspot.com",
  messagingSenderId: "635632775818",
  appId: "1:635632775818:web:76c52cc1cd0a2f866d7373"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);