import { useLoading } from 'vue-loading-overlay';

const loadingoption = useLoading({
    loader: 'spinner',
    backgroundColor: '#60C1C0',
    color: '#3a7c7b',
    opacity: 0.5,
    zIndex: 999999,
    width: 64,
    height: 64,
    isFullPage: false
});

export default loadingoption