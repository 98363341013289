import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
    props: true,
    meta: {
      requiresVisitor: true,
      layout: "landing",
    },
  },
  {
    path: "/",
    name: "dashbord",
    component: () =>
      import(/* webpackChunkName: "dashbord" */ "../views/DashbordView.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "Dashboard" }],
    },
  },
  {
    path: "/add-announcement",
    name: "addannouncement",
    component: () =>
      import(
        /* webpackChunkName: "addannouncement" */ "../views/Announcement/AddAnnouncementView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addannouncement" }],
    },
  },
  {
    path: "/edit-announcement/:slug",
    name: "editannouncement",
    component: () =>
      import(
        /* webpackChunkName: "editannouncement" */ "../views/Announcement/EditAnnouncementView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "editannouncement" }],
    },
  },
  {
    path: "/annoncement-list",
    name: "annoncementlist",
    component: () =>
      import(
        /* webpackChunkName: "annoncementlist" */ "../views/Announcement/AnnouncementlistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "annoncementlist" }],
    },
  },
  {
    path: "/announcement-banner",
    name: "annoncementbanner",
    component: () =>
      import(
        /* webpackChunkName: "annoncementbanner" */ "../views/Announcement/AnnouncementBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "annoncementbanner" }],
    },
  },
  {
    path: "/add-business",
    name: "addbusiness",
    component: () =>
      import(
        /* webpackChunkName: "addbusiness" */ "../views/Business/AddBusinessView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addbusiness" }],
    },
  },
  {
    path: "/business-list",
    name: "businesslist",
    component: () =>
      import(
        /* webpackChunkName: "businesslist" */ "../views/Business/BusinesslistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "businesslist" }],
    },
  },
  {
    path: "/business-details/:slug",
    name: "businessdetails",
    component: () =>
      import(
        /* webpackChunkName: "businessdetails" */ "../views/Business/BusinessDetailsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "businessdetails" }],
    },
  },
  {
    path: "/business-banner",
    name: "businessbanner",
    component: () =>
      import(
        /* webpackChunkName: "businessbanner" */ "../views/Business/BusinessBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "businessbanner" }],
    },
  },
  {
    path: "/business-category",
    name: "businesscategory",
    component: () =>
      import(
        /* webpackChunkName: "businesscategory" */ "../views/Business/BusinessCategoryView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "businesscategory" }],
    },
  },
  {
    path: "/business-subcategory",
    name: "businesssubcategory",
    component: () =>
      import(
        /* webpackChunkName: "businesssubcategory" */ "../views/Business/BusinessSubCategoryView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "businesssubcategory" }],
    },
  },

  // Cars Start
  {
    path: "/add-cars",
    name: "addcars",
    component: () =>
      import(
        /* webpackChunkName: "addcars" */ "../views/Cars/AddCarsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addcars" }],
    },
  },
  {
    path: "/edit-cars/:slug",
    name: "editcars",
    component: () =>
      import(
        /* webpackChunkName: "editcars" */ "../views/Cars/AddCarsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "editcars" }],
    },
  },
  {
    path: "/cars-list",
    name: "carslist",
    component: () =>
      import(
        /* webpackChunkName: "carslist" */ "../views/Cars/CarslistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "carsSlist" }],
    },
  },
  {
    path: "/cars-banner",
    name: "carsbanner",
    component: () =>
      import(
        /* webpackChunkName: "carsbanner" */ "../views/Cars/CarsBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "cars" }],
    },
  },
  {
    path: "/cars-braind",
    name: "carsbraind",
    component: () =>
      import(
        /* webpackChunkName: "carsbraind" */ "../views/Cars/CarBraindView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "carsbraind" }],
    },
  },
  {
    path: "/cars-type",
    name: "cartype",
    component: () =>
      import(
        /* webpackChunkName: "cartype" */ "../views/Cars/CarTypeView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "cartype" }],
    },
  },
  {
    path: "/cars-model",
    name: "carmodel",
    component: () =>
      import(
        /* webpackChunkName: "carmodel" */ "../views/Cars/CarModelView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "carmodel" }],
    },
  },
  {
    path: "/cars-gearbox",
    name: "cargearbox",
    component: () =>
      import(
        /* webpackChunkName: "cargearbox" */ "../views/Cars/CarGearboxView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "cargearbox" }],
    },
  },
  // Cars End

  {
    path: "/add-coupons",
    name: "addcoupons",
    component: () =>
      import(
        /* webpackChunkName: "addcoupons" */ "../views/Coupons/AddCouponsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addcoupons" }],
    },
  },
  {
    path: "/edit-coupons/:slug",
    name: "editcoupons",
    component: () =>
      import(
        /* webpackChunkName: "editcoupons" */ "../views/Coupons/AddCouponsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "editcoupons" }],
    },
  },
  {
    path: "/marchant-coupons",
    name: "couponsmarchant",
    component: () =>
      import(
        /* webpackChunkName: "couponsmarchant" */ "../views/Coupons/CouponsMarchantView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "couponsmarchant" }],
    },
  },
  {
    path: "/coupons-list",
    name: "couponslist",
    component: () =>
      import(
        /* webpackChunkName: "couponslist" */ "../views/Coupons/CouponslistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "couponslist" }],
    },
  },
  {
    path: "/coupons-banner",
    name: "couponsbanner",
    component: () =>
      import(
        /* webpackChunkName: "couponsbanner" */ "../views/Coupons/CouponsBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "couponsbanner" }],
    },
  },
  {
    path: "/add-directory",
    name: "adddirectory",
    component: () =>
      import(
        /* webpackChunkName: "adddiretory" */ "../views/Directory/AddDirectoryView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "adddirectory" }],
    },
  }, {
    path: "/directory-list",
    name: "directorylist",
    component: () =>
      import(
        /* webpackChunkName: "diretorylist" */ "../views/Directory/DirectorylistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "directorylist" }],
    },
  },
  {
    path: "/directory-banner",
    name: "directorybanner",
    component: () =>
      import(
        /* webpackChunkName: "diretorybanner" */ "../views/Directory/DirectoryBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "directorybanner" }],
    },
  },
  // Events Start
  {
    path: "/add-events",
    name: "addevents",
    component: () =>
      import(
        /* webpackChunkName: "addevents" */ "../views/Events/AddEventsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addevents" }],
    },
  },
  {
    path: "/events-list",
    name: "eventslist",
    component: () =>
      import(
        /* webpackChunkName: "eventslist" */ "../views/Events/EventslistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "eventslist" }],
    },
  },
  {
    path: "/events-banner",
    name: "eventsbanner",
    component: () =>
      import(
        /* webpackChunkName: "eventsbanner" */ "../views/Events/EventsBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "eventsbanner" }],
    },
  },
  {
    path: "/events-category",
    name: "eventscategory",
    component: () =>
      import(
        /* webpackChunkName: "eventscategory" */ "../views/Events/EventsCategoryView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "eventscategory" }],
    },
  },
  {
    path: "/edit-events/:slug",
    name: "editseventsRouter",
    component: () =>
      import(
        /* webpackChunkName: "editseventsRouter" */ "../views/Events/EditEventsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "editseventsRouter" }],
    },
  },
  // Events End
  {
    path: "/add-horoscope",
    name: "addhoroscope",
    component: () =>
      import(
        /* webpackChunkName: "addhoroscope" */ "../views/Horoscope/AddHoroscopeView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addhoroscope" }],
    },
  },
  {
    path: "/horoscope-list",
    name: "horoscopelist",
    component: () =>
      import(
        /* webpackChunkName: "horoscopelist" */ "../views/Horoscope/HoroscopelistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "horoscopelist" }],
    },
  },
  {
    path: "/horoscope-banner",
    name: "horoscopebanner",
    component: () =>
      import(
        /* webpackChunkName: "horoscopebanner" */ "../views/Horoscope/HoroscopeBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "horoscopebanner" }],
    },
  },
  {
    path: "/edit-horoscope/:slug",
    name: "edithoroscope",
    component: () =>
      import(
        /* webpackChunkName: "edithoroscope" */ "../views/Horoscope/EditHoroscopeView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "edithoroscope" }],
    },
  },
  {
    path: "/add-jobs",
    name: "addjobs",
    component: () =>
      import(
        /* webpackChunkName: "addjobsr" */ "../views/Jobs/AddJobsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addjobs" }],
    },
  },
  {
    path: "/jobs-list",
    name: "jobslist",
    component: () =>
      import(
        /* webpackChunkName: "jobslist" */ "../views/Jobs/JobslistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "jobslist" }],
    },
  },
  {
    path: "/jobs-banner",
    name: "jobsbanner",
    component: () =>
      import(
        /* webpackChunkName: "jobsbanner" */ "../views/Jobs/JobsBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "jobsbanner" }],
    },
  },
  {
    path: "/jobs-category",
    name: "jobscategory",
    component: () =>
      import(
        /* webpackChunkName: "jobscategory" */ "../views/Jobs/JobsCategoryView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "jobscategory" }],
    },
  },
  {
    path: "/edit-jobs/:slug",
    name: "editjobs",
    component: () =>
      import(
        /* webpackChunkName: "editjobs" */ "../views/Jobs/EditJobsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "editjobs" }],
    },
  },
  {
    path: "/jobs-form",
    name: "jobsform",
    component: () =>
      import(
        /* webpackChunkName: "jobsform" */ "../views/Jobs/JobsFormView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "jobsform" }],
    },
  },
  {
    path: "/add-promotions",
    name: "addpromotions",
    component: () =>
      import(
        /* webpackChunkName: "addpromotions" */ "../views/Promotions/AddPromotionsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addpromotions" }],
    },
  },
  {
    path: "/edit-promotions/:slug",
    name: "editpromotions",
    component: () =>
      import(
        /* webpackChunkName: "editpromotions" */ "../views/Promotions/EditPromotionsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "editpromotions" }],
    },
  },
  {
    path: "/promotions-list",
    name: "promotionslist",
    component: () =>
      import(
        /* webpackChunkName: "promotionslist" */ "../views/Promotions/PromotionslistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "promotionslist" }],
    },
  },
  {
    path: "/promotions-banner",
    name: "promotionsbanner",
    component: () =>
      import(
        /* webpackChunkName: "promotionsbanner" */ "../views/Promotions/PromotionsBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "promotionsbanner" }],
    },
  },
  {
    path: "/add-property",
    name: "addproperty",
    component: () =>
      import(
        /* webpackChunkName: "addproperty" */ "../views/Property/AddPropertyView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addproperty" }],
    },
  },
  {
    path: "/edit-property/:slug",
    name: "editproperty",
    component: () =>
      import(
        /* webpackChunkName: "editproperty" */ "../views/Property/AddPropertyView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "editproperty" }],
    },
  },
  {
    path: "/property-list",
    name: "propertylist",
    component: () =>
      import(
        /* webpackChunkName: "propertylist" */ "../views/Property/PropertylistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "propertylist" }],
    },
  },
  {
    path: "/property-banner",
    name: "propertybanner",
    component: () =>
      import(
        /* webpackChunkName: "propertybanner" */ "../views/Property/PropertyBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "propertybanner" }],
    },
  },
  {
    path: "/property-category",
    name: "propertycategory",
    component: () =>
      import(
        /* webpackChunkName: "propertycategory" */ "../views/Property/PropertyCategoryView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "propertycategory" }],
    },
  },
  {
    path: "/add-tenders",
    name: "addtenders",
    component: () =>
      import(
        /* webpackChunkName: "addtendersr" */ "../views/Tenders/AddTendersView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addtenders" }],
    },
  },
  {
    path: "/tenders-list",
    name: "tenderslist",
    component: () =>
      import(
        /* webpackChunkName: "tenderslist" */ "../views/Tenders/TenderslistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "tenderslist" }],
    },
  },
  {
    path: "/tenders-banner",
    name: "tendersbanner",
    component: () =>
      import(
        /* webpackChunkName: "tendersbanner" */ "../views/Tenders/TendersBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "tendersbanner" }],
    },
  },
  {
    path: "/tenders-category",
    name: "tenderscategory",
    component: () =>
      import(
        /* webpackChunkName: "tenderscategory" */ "../views/Tenders/TendersCategoryView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "tenderscategory" }],
    },
  },
  {
    path: "/edit-tenders/:slug",
    name: "edittenders",
    component: () =>
      import(
        /* webpackChunkName: "edittenders" */ "../views/Tenders/EditTendersView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "edittenders" }],
    },
  },
  {
    path: "/tenders-email/:slug",
    name: "tendersemail",
    component: () =>
      import(
        /* webpackChunkName: "tendersemail" */ "../views/Tenders/TendersEmailView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "tendersemail" }],
    },
  },
  {
    path: "/add-news",
    name: "addnews",
    component: () =>
      import(
        /* webpackChunkName: "tendersbanner" */ "../views/News/AddNewsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "addnews" }],
    },
  },
  {
    path: "/news-list",
    name: "newslist",
    component: () =>
      import(
        /* webpackChunkName: "tendersbanner" */ "../views/News/NewslistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "newslist" }],
    },
  },
  {
    path: "/news-banner",
    name: "newsbanner",
    component: () =>
      import(
        /* webpackChunkName: "tendersbanner" */ "../views/News/NewsBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "newssbanner" }],
    },
  },
  {
    path: "/edit-news/:slug",
    name: "editnews",
    component: () =>
      import(
        /* webpackChunkName: "editnews" */ "../views/News/EditNewsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "editnews" }],
    },
  },
  {
    path: "/settings-countries",
    name: "settingscountries",
    component: () =>
      import(
        /* webpackChunkName: "settingscountries" */ "../views/Settings/SettingsCountries.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "SettingsCountries" }],
    },
  },
  // {
  //   path: "/settings-states",
  //   name: "settingsstates",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "settingsstates" */ "../views/Settings/SettingsStates.vue"
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "default",
  //     breadCrumb: [{ name: "SettingsStates" }],
  //   },
  // },
  {
    path: "/settings-cities",
    name: "settingscities",
    component: () =>
      import(
        /* webpackChunkName: "settingscities" */ "../views/Settings/SettingsCities.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "SettingsCities" }],
    },
  },
  {
    path: "/settings-website",
    name: "settingswebsite",
    component: () =>
      import(
        /* webpackChunkName: "settingswebsite" */ "../views/Settings/SettingsWebsite.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "SettingsWebsite" }],
    },
  },
  {
    path: "/settings-meta",
    name: "settingsmeta",
    component: () =>
      import(
        /* webpackChunkName: "settingsmeta" */ "../views/Settings/SettingsMeta.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "settingsmeta" }],
    },
  },
  {
    path: "/settings-notification",
    name: "settingsnotification",
    component: () =>
      import(
        /* webpackChunkName: "settingsnotification" */ "../views/Settings/SettingsNotification.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "settingsnotification" }],
    },
  },
  {
    path: "/settings-newsletter",
    name: "settingsnewsletter",
    component: () =>
      import(
        /* webpackChunkName: "SettingsNewsLetter" */ "../views/Settings/SettingsNewsLetter.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "settingsnewsletter" }],
    },
  },
  {
    path: "/settings-loglist",
    name: "settingsloglist",
    component: () =>
      import(
        /* webpackChunkName: "settingsloglist" */ "../views/Settings/SettingsLogListView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "SettingsLogList" }],
    },
  },
  {
    path: "/web-site-home",
    name: "websitehome",
    component: () =>
      import(
        /* webpackChunkName: "websitehome" */ "../views/Settings/WebSiteHome.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "websitehome" }],
    },
  },

  {
    path: "/admin-allStaffsView",
    name: "AllStaffsView",
    component: () =>
      import(
        /* webpackChunkName: "AllStaffsView" */ "../views/Staffs/AllStaffsView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "AllStaffsView" }],
    },
  },
  {
    path: "/admin-AddNewStaffs",
    name: "AddNewStaffs",
    component: () =>
      import(
        /* webpackChunkName: "AddNewStaffs" */ "../views/Staffs/AddNewStaffs.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "AddNewStaffs" }],
    },
  },
  {
    path: "/admin-addNewRolesView",
    name: "NewRolesView",
    component: () =>
      import(
        /* webpackChunkName: "NewRolesView" */ "../views/Staffs/AddNewRolesView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "NewRolesView" }],
    },
  },
  {
    path: "/admin-rolesView",
    name: "RolesView",
    component: () =>
      import(
        /* webpackChunkName: "RolesView" */ "../views/Staffs/RolesView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "RolesView" }],
    },
  },

  {
    path: "/customers-list",
    name: "customerslist",
    component: () =>
      import(
        /* webpackChunkName: "customerslist" */ "../views/Customers/CustomerslistView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "Customerslist" }],
    },
  },
  {
    path: "/add-customers",
    name: "addcustomers",
    component: () =>
      import(
        /* webpackChunkName: "addcustomers" */ "../views/Customers/AddCustomersView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "AddCustomers" }],
    },
  },
  {
    path: "/others-pages-list",
    name: "otherspageslist",
    component: () =>
      import(
        /* webpackChunkName: "otherspageslist" */ "../views/Others/OthersPagesList.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "OthersPagesList" }],
    },
  },
  {
    path: "/others-pages-list/:slug",
    name: "otherslist",
    component: () =>
      import(
        /* webpackChunkName: "otherslist" */ "../views/Others/OthersListView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "OthersList" }],
    },
  },
  {
    path: "/others-pages-list/:slug/others-banner",
    name: "othersbanner",
    component: () =>
      import(
        /* webpackChunkName: "othersbanner" */ "../views/Others/OthersBannerView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "OthersBanner" }],
    },
  },
  {
    path: "/others-pages-list/:slug/add-others",
    name: "addothers",
    component: () =>
      import(
        /* webpackChunkName: "addothers" */ "../views/Others/AddOthersView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "AddOthers" }],
    },
  },
  {
    path: "/others-pages-list/:slug/add-others/:edit",
    name: "editothers",
    component: () =>
      import(
        /* webpackChunkName: "editothers" */ "../views/Others/AddOthersView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "default",
      breadCrumb: [{ name: "EditOthers" }],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
