<script setup>
import { onMounted, ref, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { signOut } from "firebase/auth";
import { auth } from "@/firebase";
const props = defineProps({
  menuActive: {
    type: Number,
    default: 0,
  },
});
const classCall = props.menuActive;
const feather = require("feather-icons");
const store = useStore();
const router = useRouter();
const secretMessage = ref("");
const username = ref("Admin");
const userType = ref("Admin");
const menuAuth = ref(store.getters.isAccess != null
      ? store.getters.isAccess
      : {
          Customers: true,
          Business: true,
          Announcement: true,
          Promotions: true,
          Events: true,
          Tenders: true,
          Property: true,
          Cars: true,
          Jobs: true,
          Horoscope: true,
          News: true,
          Others: true,
          Settings: true,
          Notification: true
        });
onMounted(async () => {
  feather.replace();
  // userType.value = store.getters.getUser.user_type ;
  // username.value = store.getters.getUser.name;
  // secretMessage.value = await AuthService.getSecretContent();
  // document.getElementById("dropdownId").addEventListener("show.bs.dropdown");
});
const logout = async () => {
  await signOut(auth)
    .then((res) => {})
    .catch((err) => {
      console.log(err);
    });
  store.dispatch("logout").then(() => {
    !store.state.isLoggedIn ? router.go({ path: "/login" }) : "";
    // router.push("/login")
  });
};
</script>
<template>
  <!-- <div> -->
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar">
      <router-link to="/" class="flex mr-auto">
        <img alt="Sombardc Admin" class="w-6" src="@/assets/images/Logo.png" />
      </router-link>
      <a href="javascript:void(0)" id="mobile-menu-toggler">
        <i
          data-feather="bar-chart-2"
          class="w-8 h-8 text-white transform -rotate-90"
        ></i>
      </a>
    </div>
    <ul class="border-t border-white/[0.08] py-5 hidden">
      <li>
        <router-link to="/" class="menu menu--active">
          <div class="menu__icon"><i data-feather="home"></i></div>
          <div class="menu__title">
            Dashboard
            <i
              data-feather="chevron-down"
              class="menu__sub-icon transform rotate-180"
            ></i>
          </div>
        </router-link>
        <!-- <ul class="menu__sub-open">
            <li>
              <a href="index.html" class="menu menu--active">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Overview 1</div>
              </a>
            </li>
            <li>
              <a href="side-menu-light-dashboard-overview-2.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Overview 2</div>
              </a>
            </li>
            <li>
              <a href="side-menu-light-dashboard-overview-3.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Overview 3</div>
              </a>
            </li>
          </ul> -->
      </li>
      <li>
        <router-link to="/" class="menu">
          <div class="menu__icon"><i data-feather="box"></i></div>
          <div class="menu__title">
            Menu Layout
            <i data-feather="chevron-down" class="menu__sub-icon"></i>
          </div>
        </router-link>
        <!-- <ul class="">
              <li>
                <a href="index.html" class="menu menu--active">
                  <div class="menu__icon"><i data-feather="activity"></i></div>
                  <div class="menu__title">Side Menu</div>
                </a>
              </li>
              <li>
                <a
                  href="simple-menu-light-dashboard-overview-1.html"
                  class="menu menu--active"
                >
                  <div class="menu__icon"><i data-feather="activity"></i></div>
                  <div class="menu__title">Simple Menu</div>
                </a>
              </li>
              <li>
                <a
                  href="top-menu-light-dashboard-overview-1.html"
                  class="menu menu--active"
                >
                  <div class="menu__icon"><i data-feather="activity"></i></div>
                  <div class="menu__title">Top Menu</div>
                </a>
              </li>
            </ul> -->
      </li>
      <li>
        <router-link to="/" class="menu">
          <div class="menu__icon"><i data-feather="inbox"></i></div>
          <div class="menu__title">Inbox</div>
        </router-link>
      </li>
      <li>
        <a href="side-menu-light-file-manager.html" class="menu">
          <div class="menu__icon"><i data-feather="hard-drive"></i></div>
          <div class="menu__title">File Manager</div>
        </a>
      </li>
      <li>
        <a href="side-menu-light-point-of-sale.html" class="menu">
          <div class="menu__icon"><i data-feather="credit-card"></i></div>
          <div class="menu__title">Point of Sale</div>
        </a>
      </li>
      <li>
        <a href="side-menu-light-chat.html" class="menu">
          <div class="menu__icon"><i data-feather="message-square"></i></div>
          <div class="menu__title">Chat</div>
        </a>
      </li>
      <li>
        <a href="side-menu-light-post.html" class="menu">
          <div class="menu__icon"><i data-feather="file-text"></i></div>
          <div class="menu__title">Post</div>
        </a>
      </li>
      <li>
        <a href="side-menu-light-calendar.html" class="menu">
          <div class="menu__icon"><i data-feather="calendar"></i></div>
          <div class="menu__title">Calendar</div>
        </a>
      </li>
      <li class="menu__devider my-6"></li>
      <li>
        <a href="javascript:;" class="menu">
          <div class="menu__icon"><i data-feather="edit"></i></div>
          <div class="menu__title">
            Crud <i data-feather="chevron-down" class="menu__sub-icon"></i>
          </div>
        </a>
        <ul class="">
          <li>
            <a href="side-menu-light-crud-data-list.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Data List</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-crud-form.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Form</div>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="javascript:;" class="menu">
          <div class="menu__icon"><i data-feather="users"></i></div>
          <div class="menu__title">
            Users <i data-feather="chevron-down" class="menu__sub-icon"></i>
          </div>
        </a>
        <ul class="">
          <li>
            <a href="side-menu-light-users-layout-1.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Layout 1</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-users-layout-2.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Layout 2</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-users-layout-3.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Layout 3</div>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="javascript:;" class="menu">
          <div class="menu__icon"><i data-feather="trello"></i></div>
          <div class="menu__title">
            Profile <i data-feather="chevron-down" class="menu__sub-icon"></i>
          </div>
        </a>
        <ul class="">
          <li>
            <a href="side-menu-light-profile-overview-1.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Overview 1</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-profile-overview-2.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Overview 2</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-profile-overview-3.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Overview 3</div>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="javascript:;" class="menu">
          <div class="menu__icon"><i data-feather="layout"></i></div>
          <div class="menu__title">
            Pages <i data-feather="chevron-down" class="menu__sub-icon"></i>
          </div>
        </a>
        <ul class="">
          <li>
            <a href="javascript:;" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">
                Wizards
                <i data-feather="chevron-down" class="menu__sub-icon"></i>
              </div>
            </a>
            <ul class="">
              <li>
                <a href="side-menu-light-wizard-layout-1.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 1</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-wizard-layout-2.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 2</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-wizard-layout-3.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 3</div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:;" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">
                Blog
                <i data-feather="chevron-down" class="menu__sub-icon"></i>
              </div>
            </a>
            <ul class="">
              <li>
                <a href="side-menu-light-blog-layout-1.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 1</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-blog-layout-2.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 2</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-blog-layout-3.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 3</div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:;" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">
                Pricing
                <i data-feather="chevron-down" class="menu__sub-icon"></i>
              </div>
            </a>
            <ul class="">
              <li>
                <a href="side-menu-light-pricing-layout-1.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 1</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-pricing-layout-2.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 2</div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:;" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">
                Invoice
                <i data-feather="chevron-down" class="menu__sub-icon"></i>
              </div>
            </a>
            <ul class="">
              <li>
                <a href="side-menu-light-invoice-layout-1.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 1</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-invoice-layout-2.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 2</div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:;" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">
                FAQ <i data-feather="chevron-down" class="menu__sub-icon"></i>
              </div>
            </a>
            <ul class="">
              <li>
                <a href="side-menu-light-faq-layout-1.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 1</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-faq-layout-2.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 2</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-faq-layout-3.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Layout 3</div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="login-light-login.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Login</div>
            </a>
          </li>
          <li>
            <a href="login-light-register.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Register</div>
            </a>
          </li>
          <li>
            <a href="main-light-error-page.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Error Page</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-update-profile.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Update profile</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-change-password.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Change Password</div>
            </a>
          </li>
        </ul>
      </li>
      <li class="menu__devider my-6"></li>
      <li>
        <a href="javascript:;" class="menu">
          <div class="menu__icon"><i data-feather="inbox"></i></div>
          <div class="menu__title">
            Components
            <i data-feather="chevron-down" class="menu__sub-icon"></i>
          </div>
        </a>
        <ul class="">
          <li>
            <a href="javascript:;" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">
                Table
                <i data-feather="chevron-down" class="menu__sub-icon"></i>
              </div>
            </a>
            <ul class="">
              <li>
                <a href="side-menu-light-regular-table.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Regular Table</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-tabulator.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Tabulator</div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:;" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">
                Overlay
                <i data-feather="chevron-down" class="menu__sub-icon"></i>
              </div>
            </a>
            <ul class="">
              <li>
                <a href="side-menu-light-modal.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Modal</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-slide-over.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Slide Over</div>
                </a>
              </li>
              <li>
                <a href="side-menu-light-notification.html" class="menu">
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Notification</div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="side-menu-light-accordion.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Accordion</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-button.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Button</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-alert.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Alert</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-progress-bar.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Progress Bar</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-tooltip.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Tooltip</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-dropdown.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Dropdown</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-typography.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Typography</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-icon.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Icon</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-loading-icon.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Loading Icon</div>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="javascript:;" class="menu">
          <div class="menu__icon"><i data-feather="sidebar"></i></div>
          <div class="menu__title">
            Forms <i data-feather="chevron-down" class="menu__sub-icon"></i>
          </div>
        </a>
        <ul class="">
          <li>
            <a href="side-menu-light-regular-form.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Regular Form</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-datepicker.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Datepicker</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-tom-select.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Tom Select</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-file-upload.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">File Upload</div>
            </a>
          </li>
          <li>
            <a href="javascript:;" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">
                Wysiwyg Editor
                <i data-feather="chevron-down" class="menu__sub-icon"></i>
              </div>
            </a>
            <ul class="">
              <li>
                <a
                  href="side-menu-light-wysiwyg-editor-classic.html"
                  class="menu"
                >
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Classic</div>
                </a>
              </li>
              <li>
                <a
                  href="side-menu-light-wysiwyg-editor-inline.html"
                  class="menu"
                >
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Inline</div>
                </a>
              </li>
              <li>
                <a
                  href="side-menu-light-wysiwyg-editor-balloon.html"
                  class="menu"
                >
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Balloon</div>
                </a>
              </li>
              <li>
                <a
                  href="side-menu-light-wysiwyg-editor-balloon-block.html"
                  class="menu"
                >
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Balloon Block</div>
                </a>
              </li>
              <li>
                <a
                  href="side-menu-light-wysiwyg-editor-document.html"
                  class="menu"
                >
                  <div class="menu__icon"><i data-feather="zap"></i></div>
                  <div class="menu__title">Document</div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="side-menu-light-validation.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Validation</div>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="javascript:;" class="menu">
          <div class="menu__icon"><i data-feather="hard-drive"></i></div>
          <div class="menu__title">
            Widgets <i data-feather="chevron-down" class="menu__sub-icon"></i>
          </div>
        </a>
        <ul class="">
          <li>
            <a href="side-menu-light-chart.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Chart</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-slider.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Slider</div>
            </a>
          </li>
          <li>
            <a href="side-menu-light-image-zoom.html" class="menu">
              <div class="menu__icon"><i data-feather="activity"></i></div>
              <div class="menu__title">Image Zoom</div>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- END: Mobile Menu -->
  <!-- BEGIN: Top Bar -->
  <div
    class="border-b border-white/[0.08] -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0 mb-0"
  >
    <div class="top-bar-boxed flex items-center">
      <!-- BEGIN: Logo -->
      <router-link to="/" class="-intro-x hidden md:flex">
        <img alt="Logo" src="@/assets/images/Logo.png" width="100" />
        <!-- <span class="text-white text-lg ml-3"> Sombardc </span> -->
      </router-link>
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <nav aria-label="breadcrumb" class="-intro-x h-full mr-auto">
        <!-- <ol class="breadcrumb breadcrumb-light">
              <li class="breadcrumb-item"><a href="#">Application</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Dashboard
              </li>
            </ol> -->
      </nav>
      <!-- END: Breadcrumb -->
      <!-- BEGIN: Notifications -->
      <!-- <div class="intro-x dropdown mr-4 sm:mr-6">
        <div class="dropdown-toggle notification notification--light notification--bullet cursor-pointer" role="button"
          aria-expanded="false" data-bs-toggle="dropdown">
          <i data-feather="bell" class="notification__icon dark:text-slate-500"></i>
        </div>
        <div class="notification-content pt-2 dropdown-menu">
          <div class="notification-content__box dropdown-content">
            <div class="notification-content__title">Notifications</div>
            <div class="cursor-pointer relative flex items-center">
              <div class="w-12 h-12 flex-none image-fit mr-1">
                <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="@/assets/images/profile-6.jpg" />
                <div class="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
              </div>
              <div class="ml-2 overflow-hidden">
                <div class="flex items-center">
                  <a href="javascript:;" class="font-medium truncate mr-5">Johnny Depp</a>
                  <div class="text-xs text-slate-400 ml-auto whitespace-nowrap">
                    01:10 PM
                  </div>
                </div>
                <div class="w-full truncate text-slate-500 mt-0.5">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomi
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- END: Notifications -->
      <!-- BEGIN: Account Menu -->
      <div class="intro-x dropdown w-8 h-8">
        <div
          class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
          role="button"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          id="dropdownId"
        >
          <img
            alt="Rubick Tailwind HTML Admin Template"
            src="@/assets/images/profile-2.jpg"
          />
        </div>
        <div class="dropdown-menu w-56">
          <ul
            class="dropdown-content bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white"
            aria-labelledby="dropdownId"
          >
            <li class="p-2">
              <div class="font-medium" style="text-transform: capitalize">
                {{ username }}
              </div>
              <div
                class="text-xs text-white/60 mt-0.5 dark:text-slate-500"
                style="text-transform: capitalize"
              >
                {{ userType }}
              </div>
            </li>
            <!-- <li>
              <hr class="dropdown-divider border-white/[0.08]" />
            </li>
            <li>
              <a href="javascript:void(0)" class="dropdown-item hover:bg-white/5">
                <i data-feather="user" class="w-4 h-4 mr-2"></i> Profile
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" class="dropdown-item hover:bg-white/5">
                <i data-feather="lock" class="w-4 h-4 mr-2"></i> Reset Password
              </a>
            </li>
            <li>
              <hr class="dropdown-divider border-white/[0.08]" />
            </li> -->
            <li>
              <a
                href="javascript:void(0)"
                @click="logout"
                class="dropdown-item hover:bg-white/5"
              >
                <i data-feather="toggle-right" class="w-4 h-4 mr-2"></i>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- END: Account Menu -->
    </div>
  </div>
  <!-- END: Top Bar -->
  <!-- BEGIN: Top Menu -->
  <nav class="top-nav">
    <ul>
      <li>
        <router-link
          to="/"
          class="top-menu"
          :class="[classCall === 1 ? 'top-menu--active' : '']"
        >
          <div class="top-menu__title">Dashboard</div>
        </router-link>
      </li>
      <li v-if="menuAuth.Customers === true">
        <router-link
          to=""
          class="top-menu"
          :class="[menuActive == 11 ? 'top-menu--active' : '']"
        >
          <div class="top-menu__title">
            Customers
            <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
          </div>
        </router-link>
        <ul class="top-menu__sub-open">
          <!-- <li>
            <router-link to="/add-customers" class="top-menu">
              <div class="top-menu__title">Add Customers</div>
            </router-link>
          </li> -->
          <li>
            <router-link to="/customers-list" class="top-menu">
              <div class="top-menu__title">Customers List</div>
            </router-link>
          </li>
        </ul>
      </li>
      <li v-if="menuAuth.Business === true">
        <router-link
          to=""
          class="top-menu"
          :class="[menuActive == 2 ? 'top-menu--active' : '']"
        >
          <div class="top-menu__title">
            Business
            <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
          </div>
        </router-link>
        <ul class="">
          <li>
            <router-link to="/business-banner" class="top-menu">
              <div class="top-menu__title">Banner</div>
            </router-link>
          </li>
          <li>
            <router-link to="/business-list" class="top-menu">
              <div class="top-menu__title">Business list</div>
            </router-link>
          </li>
          <li>
            <router-link to="/add-business" class="top-menu">
              <div class="top-menu__title">Add Business</div>
            </router-link>
          </li>
          <li>
            <router-link to="/business-category" class="top-menu">
              <div class="top-menu__title">Business Categories</div>
            </router-link>
          </li>
        </ul>
      </li>
      <li v-if="menuAuth.Announcement === true">
        <router-link
          to=""
          class="top-menu"
          :class="[menuActive == 3 ? 'top-menu--active' : '']"
        >
          <!-- <div class="top-menu__icon"><i data-feather="box"></i></div> -->
          <div class="top-menu__title">
            Announcement
            <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
          </div>
        </router-link>
        <ul class="">
          <li>
            <router-link to="/announcement-banner" class="top-menu">
              <div class="top-menu__title">Banner</div>
            </router-link>
          </li>
          <li>
            <router-link to="/annoncement-list" class="top-menu">
              <div class="top-menu__title">Announcement list</div>
            </router-link>
          </li>
          <li>
            <router-link to="/add-announcement" class="top-menu">
              <div class="top-menu__title">Add Announcement</div>
            </router-link>
          </li>
          <!-- <li>
              <router-link to="/" class="top-menu">
                <div class="top-menu__title">ANNOUNCEMENT LIST</div>
              </router-link>
            </li>
            <li>
              <router-link to="/" class="top-menu">
              <div class="top-menu__title">ANNOUNCEMENT LIST</div>
            </router-link>
          </li> -->
        </ul>
      </li>
      <li v-if="menuAuth.Promotions === true">
        <router-link
          to=""
          class="top-menu"
          :class="[menuActive == 4 ? 'top-menu--active' : '']"
        >
          <!-- <div class="top-menu__icon"><i data-feather="activity"></i></div> -->
          <div class="top-menu__title">
            Promotions
            <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
          </div>
        </router-link>
        <ul class="">
          <li>
            <router-link to="/promotions-banner" class="top-menu">
              <div class="top-menu__title">Banner</div>
            </router-link>
          </li>
          <li>
            <router-link to="/promotions-list" class="top-menu">
              <div class="top-menu__title">Promotions list</div>
            </router-link>
          </li>
          <li>
            <router-link to="/add-promotions" class="top-menu">
              <div class="top-menu__title">Add Pomotions</div>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/" class="top-menu">
              <div class="top-menu__title">PROMOTIONS LIST</div>
            </router-link>
          </li> -->
        </ul>
        <!-- <ul class="">
            <li>
              <router-link to="javascript:;" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">
                  Users
                  <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
                </div>
              </router-link>
              <ul class="">
                <li>
                  <router-link to="top-menu-light-users-layout-1.html" class="top-menu">
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 1</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="top-menu-light-users-layout-2.html" class="top-menu">
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 2</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="top-menu-light-users-layout-3.html" class="top-menu">
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 3</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="javascript:;" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">
                  Profile
                  <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
                </div>
              </router-link>
              <ul class="">
                <li>
                  <router-link
                    to="top-menu-light-profile-overview-1.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Overview 1</div>
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="top-menu-light-profile-overview-2.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Overview 2</div>
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="top-menu-light-profile-overview-3.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Overview 3</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="top-menu-light-inbox.html" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">Inbox</div>
              </router-link>
            </li>
            <li>
              <router-link to="top-menu-light-file-manager.html" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">File Manager</div>
              </router-link>
            </li>
            <li>
              <router-link to="top-menu-light-point-of-sale.html" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">Point of Sale</div>
              </router-link>
            </li>
            <li>
              <router-link to="top-menu-light-chat.html" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">Chat</div>
              </router-link>
            </li>
            <li>
              <router-link to="top-menu-light-post.html" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">Post</div>
              </router-link>
            </li>
            <li>
              <router-link to="top-menu-light-calendar.html" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">Calendar</div>
                </router-link>
              </li>
              <li>
                <router-link to="javascript:;" class="top-menu">
                  <div class="top-menu__icon">
                    <i data-feather="activity"></i>
                  </div>
                  <div class="top-menu__title">
                    Crud
                    <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
                  </div>
                </router-link>
                <ul class="">
                  <li>
                    <router-link
                      to="side-menu-light-crud-data-list.html"
                      class="top-menu"
                    >
                      <div class="top-menu__icon"><i data-feather="zap"></i></div>
                      <div class="top-menu__title">Data List</div>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="side-menu-light-crud-form.html" class="top-menu">
                      <div class="top-menu__icon"><i data-feather="zap"></i></div>
                      <div class="top-menu__title">Form</div>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul> -->
      </li>
      <li v-if="menuAuth.Events === true">
        <router-link
          to=""
          class="top-menu"
          :class="[menuActive == 5 ? 'top-menu--active' : '']"
        >
          <!-- <div class="top-menu__icon"><i data-feather="layout"></i></div> -->
          <div class="top-menu__title">
            Events
            <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
          </div>
        </router-link>
        <ul class="">
          <li>
            <router-link to="/events-banner" class="top-menu">
              <div class="top-menu__title">Banner</div>
            </router-link>
          </li>
          <li>
            <router-link to="/events-category" class="top-menu">
              <div class="top-menu__title">Events Category</div>
            </router-link>
          </li>
          <li>
            <router-link to="/add-events" class="top-menu">
              <div class="top-menu__title">Add Events</div>
            </router-link>
          </li>
          <li>
            <router-link to="/events-list" class="top-menu">
              <div class="top-menu__title">Events list</div>
            </router-link>
          </li>
        </ul>
        <!-- <ul class="">
            <li>
              <a href="javascript:;" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">
                  Wizards
                  <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
                </div>
              </a>
              <ul class="">
                <li>
                  <a
                    href="top-menu-light-wizard-layout-1.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 1</div>
                  </a>
                </li>
                <li>
                  <a
                    href="top-menu-light-wizard-layout-2.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 2</div>
                  </a>
                </li>
                <li>
                  <a
                    href="top-menu-light-wizard-layout-3.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 3</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="javascript:;" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">
                  Blog
                  <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
                </div>
              </a>
              <ul class="">
                <li>
                  <a href="top-menu-light-blog-layout-1.html" class="top-menu">
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 1</div>
                  </a>
                </li>
                <li>
                  <a href="top-menu-light-blog-layout-2.html" class="top-menu">
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 2</div>
                  </a>
                </li>
                <li>
                  <a href="top-menu-light-blog-layout-3.html" class="top-menu">
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 3</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="javascript:;" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">
                  Pricing
                  <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
                </div>
              </a>
              <ul class="">
                <li>
                  <a
                    href="top-menu-light-pricing-layout-1.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 1</div>
                  </a>
                </li>
                <li>
                  <a
                    href="top-menu-light-pricing-layout-2.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 2</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="javascript:;" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">
                  Invoice
                  <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
                </div>
              </a>
              <ul class="">
                <li>
                  <a
                    href="top-menu-light-invoice-layout-1.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 1</div>
                  </a>
                </li>
                <li>
                  <a
                    href="top-menu-light-invoice-layout-2.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 2</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="javascript:;" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">
                  FAQ
                  <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
                </div>
              </a>
              <ul class="">
                <li>
                  <a href="top-menu-light-faq-layout-1.html" class="top-menu">
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 1</div>
                  </a>
                </li>
                <li>
                  <a href="top-menu-light-faq-layout-2.html" class="top-menu">
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 2</div>
                  </a>
                </li>
                <li>
                  <a href="top-menu-light-faq-layout-3.html" class="top-menu">
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Layout 3</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="login-light-login.html" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">Login</div>
              </a>
            </li>
            <li>
              <a href="login-light-register.html" class="top-menu">
                  <div class="top-menu__icon">
                    <i data-feather="activity"></i>
                  </div>
                  <div class="top-menu__title">Register</div>
                </a>
              </li>
              <li>
                <a href="main-light-error-page.html" class="top-menu">
                  <div class="top-menu__icon">
                    <i data-feather="activity"></i>
                  </div>
                  <div class="top-menu__title">Error Page</div>
                </a>
              </li>
              <li>
                <a href="top-menu-light-update-profile.html" class="top-menu">
                  <div class="top-menu__icon">
                    <i data-feather="activity"></i>
                  </div>
                  <div class="top-menu__title">Update profile</div>
                </a>
              </li>
              <li>
                <a href="top-menu-light-change-password.html" class="top-menu">
                  <div class="top-menu__icon">
                    <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">Change Password</div>
              </a>
            </li>
            </ul> -->
      </li>
      <li v-if="menuAuth.Tenders === true">
        <router-link
          to=""
          class="top-menu"
          :class="[menuActive == 6 ? 'top-menu--active' : '']"
        >
          <!-- <div class="top-menu__icon"><i data-feather="sidebar"></i></div> -->
          <div class="top-menu__title">
            Tenders
            <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
          </div>
        </router-link>
        <ul class="">
          <li>
            <router-link to="/tenders-banner" class="top-menu">
              <div class="top-menu__title">Banner</div>
            </router-link>
          </li>
          <li>
            <router-link to="/tenders-list" class="top-menu">
              <div class="top-menu__title">Tenders list</div>
            </router-link>
          </li>
          <li>
            <router-link to="/add-tenders" class="top-menu">
              <div class="top-menu__title">Add Tenders</div>
            </router-link>
          </li>
          <li>
            <router-link to="/tenders-category" class="top-menu">
              <div class="top-menu__title">Tenders Category</div>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/tenders-email" class="top-menu">
              <div class="top-menu__title">Tenders Email</div>
            </router-link>
          </li> -->
          <!-- <li>
              <a href="javascript:;" class="top-menu">
                <div class="top-menu__icon">
                  <i data-feather="activity"></i>
                </div>
                <div class="top-menu__title">
                  Wysiwyg Editor
                  <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
                </div>
              </a>
              <ul class="">
                <li>
                  <a
                    href="top-menu-light-wysiwyg-editor-classic.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Classic</div>
                  </a>
                </li>
                <li>
                  <a
                    href="top-menu-light-wysiwyg-editor-inline.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                    <div class="top-menu__title">Inline</div>
                  </a>
                </li>
                <li>
                  <a
                    href="top-menu-light-wysiwyg-editor-balloon.html"
                    class="top-menu"
                  >
                    <div class="top-menu__icon"><i data-feather="zap"></i></div>
                      <div class="top-menu__title">Balloon</div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="top-menu-light-wysiwyg-editor-balloon-block.html"
                      class="top-menu"
                    >
                      <div class="top-menu__icon"><i data-feather="zap"></i></div>
                      <div class="top-menu__title">Balloon Block</div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="top-menu-light-wysiwyg-editor-document.html"
                      class="top-menu"
                    >
                      <div class="top-menu__icon"><i data-feather="zap"></i></div>
                      <div class="top-menu__title">Document</div>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="top-menu-light-validation.html" class="top-menu">
                  <div class="top-menu__icon">
                    <i data-feather="activity"></i>
                  </div>
                <div class="top-menu__title">Validation</div>
              </a>
            </li> -->
        </ul>
      </li>
      <li v-if="menuAuth.Property === true">
        <router-link
          to=""
          class="top-menu"
          :class="[menuActive == 7 ? 'top-menu--active' : '']"
        >
          <div class="top-menu__title">
            Property
            <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
          </div>
        </router-link>
        <ul class="">
          <li>
            <router-link to="/property-banner" class="top-menu">
              <div class="top-menu__title">Banner</div>
            </router-link>
          </li>
          <li>
            <router-link to="/property-list" class="top-menu">
              <div class="top-menu__title">Property list</div>
            </router-link>
          </li>
          <li>
            <router-link to="/add-property" class="top-menu">
              <div class="top-menu__title">Add Property</div>
            </router-link>
          </li>
          <li>
            <router-link to="/property-category" class="top-menu">
              <div class="top-menu__title">Property Category</div>
            </router-link>
          </li>
        </ul>
      </li>
      <li v-if="menuAuth.Cars === true">
        <router-link
          to=""
          class="top-menu"
          :class="[menuActive == 8 ? 'top-menu--active' : '']"
        >
          <div class="top-menu__title">
            Cars
            <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
          </div>
        </router-link>
        <ul class="">
          <li>
            <router-link to="/cars-banner" class="top-menu">
              <div class="top-menu__title">Banner</div>
            </router-link>
          </li>
          <li>
            <router-link to="/cars-list" class="top-menu">
              <div class="top-menu__title">Cars List</div>
            </router-link>
          </li>
          <li>
            <router-link to="/add-cars" class="top-menu">
              <div class="top-menu__title">Add Cars</div>
            </router-link>
          </li>
          <li>
            <router-link to="/cars-type" class="top-menu">
              <div class="top-menu__title">Type</div>
            </router-link>
          </li>
          <li>
            <router-link to="/cars-braind" class="top-menu">
              <div class="top-menu__title">Brand</div>
            </router-link>
          </li>
          <li>
            <router-link to="/cars-model" class="top-menu">
              <div class="top-menu__title">Model</div>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/cars-gearbox" class="top-menu">
              <div class="top-menu__title">Gearbox</div>
            </router-link>
          </li> -->
        </ul>
      </li>
      <li>
        <router-link
          to=""
          class="top-menu"
          :class="[menuActive == 9 ? 'top-menu--active' : '']"
        >
          <!-- <div class="top-menu__icon"><i data-feather="hard-drive"></i></div> -->
          <div class="top-menu__title">
            More
            <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
          </div>
        </router-link>
        <ul class="">
          <!-- <li>
            <router-link to="" class="top-menu">
              <div class="top-menu__title">
                Coupons
                <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
              </div>
            </router-link>
            <ul class="">
              <li>
                <router-link to="/coupons-banner" class="top-menu">
                  <div class="top-menu__title">Banner</div>
                </router-link>
              </li>
              <li>
                <router-link to="/marchant-coupons" class="top-menu">
                  <div class="top-menu__title">Coupon Marchant</div>
                </router-link>
              </li>
              <li>
                <router-link to="/add-coupons" class="top-menu">
                  <div class="top-menu__title">Add Coupons</div>
                </router-link>
              </li>
              <li>
                <router-link to="/coupons-list" class="top-menu">
                  <div class="top-menu__title">Coupons list</div>
                </router-link>
              </li>
            </ul>
          </li> -->
          <li v-if="menuAuth.Jobs === true">
            <router-link to="" class="top-menu">
              <div class="top-menu__title">
                Jobs
                <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
              </div>
            </router-link>
            <ul class="">
              <li>
                <router-link to="/jobs-banner" class="top-menu">
                  <div class="top-menu__title">Banner</div>
                </router-link>
              </li>
              <li>
                <router-link to="/jobs-list" class="top-menu">
                  <div class="top-menu__title">Jobs list</div>
                </router-link>
              </li>
              <li>
                <router-link to="/add-jobs" class="top-menu">
                  <div class="top-menu__title">Add Jobs</div>
                </router-link>
              </li>
              <li>
                <router-link to="/jobs-category" class="top-menu">
                  <div class="top-menu__title">Jobs Categorys</div>
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="menuAuth.Horoscope === true">
            <router-link to="" class="top-menu">
              <div class="top-menu__title">
                Horoscope
                <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
              </div>
            </router-link>
            <ul class="">
              <li>
                <router-link to="/horoscope-banner" class="top-menu">
                  <div class="top-menu__title">Banner</div>
                </router-link>
              </li>
              <li>
                <router-link to="/horoscope-list" class="top-menu">
                  <div class="top-menu__title">Horoscope list</div>
                </router-link>
              </li>
              <li>
                <router-link to="/add-horoscope" class="top-menu">
                  <div class="top-menu__title">Add Horoscope</div>
                </router-link>
              </li>
              <!-- <li>
                <router-link to="/" class="top-menu">
                    <div class="top-menu__title">HOROSCOPE LIST</div>
                  </router-link> -->
              <!-- </li> -->
            </ul>
          </li>
          <li v-if="menuAuth.News === true">
            <router-link to="" class="top-menu">
              <div class="top-menu__title">
                News
                <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
              </div>
            </router-link>
            <ul class="">
              <li>
                <router-link to="/news-banner" class="top-menu">
                  <div class="top-menu__title">Banner</div>
                </router-link>
              </li>
              <li>
                <router-link to="/news-list" class="top-menu">
                  <div class="top-menu__title">News list</div>
                </router-link>
              </li>
              <li>
                <router-link to="/add-news" class="top-menu">
                  <div class="top-menu__title">Add News</div>
                </router-link>
              </li>
              <!-- <li>
                  <router-link to="/" class="top-menu">
                    <div class="top-menu__title">HOROSCOPE LIST</div>
                  </router-link> -->
              <!-- </li> -->
            </ul>
          </li>
          <li v-if="menuAuth.Others === true">
            <router-link to="/others-pages-list" class="top-menu">
              <div class="top-menu__title">Others</div>
            </router-link>
          </li>
          <li v-if="menuAuth.Notification === true">
            <router-link to="/settings-notification" class="top-menu">
              <div class="top-menu__title">Notification</div>
            </router-link>
          </li>
        </ul>
      </li>
      <li v-if="menuAuth.Settings === true">
        <router-link
          to=""
          class="top-menu"
          :class="[classCall == 10 ? 'top-menu--active' : '']"
        >
          <div class="top-menu__title">
            Settings
            <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
          </div>
        </router-link>
        <ul class="">
          <li>
            <router-link to="" class="top-menu">
              <div class="top-menu__title">
                Staffs
                <i data-feather="chevron-down" class="top-menu__sub-icon"></i>
              </div>
            </router-link>
            <ul>
              <li>
                <router-link to="/admin-AddNewStaffs" class="top-menu">
                  <div class="top-menu__title">Add New Staffs</div>
                </router-link>
              </li>
              <li>
                <router-link to="/admin-allStaffsView" class="top-menu">
                  <div class="top-menu__title">All Staffs View</div>
                </router-link>
              </li>
              <!-- <li>
                <router-link to="/admin-addNewRolesView" class="top-menu">
                  <div class="top-menu__title">Add New Roles View</div>
                </router-link>
              </li>
              <li>
                <router-link to="/admin-rolesView" class="top-menu">
                  <div class="top-menu__title">Roles View</div>
                </router-link>
              </li> -->
            </ul>
          </li>
          <li>
            <router-link to="/web-site-home" class="top-menu">
              <div class="top-menu__title">WebSite Home</div>
            </router-link>
          </li>
          <li>
            <router-link to="/settings-website" class="top-menu">
              <div class="top-menu__title">WebSite Settings</div>
            </router-link>
          </li>
          <li>
            <router-link to="/settings-countries" class="top-menu">
              <div class="top-menu__title">Countries</div>
            </router-link>
          </li>
          <li>
            <router-link to="/settings-cities" class="top-menu">
              <div class="top-menu__title">Cities</div>
            </router-link>
          </li>
          <li>
            <router-link to="/settings-meta" class="top-menu">
              <div class="top-menu__title">Meta</div>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/settings-notification" class="top-menu">
              <div class="top-menu__title">Notification</div>
            </router-link>
          </li> -->
          <li>
            <router-link to="/settings-newsletter" class="top-menu">
              <div class="top-menu__title">News Letter</div>
            </router-link>
          </li>
          <li>
            <router-link to="/settings-loglist" class="top-menu">
              <div class="top-menu__title">Log List</div>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
  <!-- END: Top Menu -->
  <!-- </div> -->
</template>
