<script setup>
import { onMounted, ref, reactive, computed } from "@vue/runtime-core";
import { useRouter, useRoute } from "vue-router";
import { bi_pencil_square, bi_geo_alt_fill, bi_plus_square, bi_dash_square } from "@/services/icon";
import { useStore } from "vuex";
import { VueDraggableNext } from 'vue-draggable-next';
import loadingoption from "@/plugin/loading";
const store = useStore();
const router = useRouter();
const props = defineProps(['imgList', 'imgType', 'directoryId', 'apiType']);
const items = computed({
    get() {
        return props.imgList ? props.imgList : []
    },
    set(val) {
        return val
    }
});
const getList = (event) => {
    return items.value
}
function checkMove(evt) {
    // console.log('Future index: ' + evt.draggedContext.futureIndex)
    // console.log('element: ' + evt.draggedContext.element.title)
}
const deleteImage = (tableId) => {
    const loader = loadingoption.show();
    let json = JSON.stringify({ id: tableId, type: 'deleteImg' });
    store.dispatch('CarsAddCall', json).then(() => {
        if (store.getters.CARSCALL.status === true) {
            router.go(router.currentRoute);
            loader.hide();
        } else {
            store.dispatch("getError", store.getters.CARSCALL.message);
            loader.hide();
        }
    })
}
const dragImgUpdate = (bus_id, api_type, img_type) => {
    const loader = loadingoption.show();
    let json = JSON.stringify({ id: bus_id, type: api_type, imgType: img_type, imgData: getList() });
    store.dispatch('CarsAddCall', json).then(() => {
        if (store.getters.CARSCALL.status == true) {
            router.go(router.currentRoute);
            loader.hide();
        } else {
            store.dispatch("getError", store.getters.CARSCALL.message);
            loader.hide();
        }
    })
}
</script>
<template>
    <section class="overflow-hidden text-neutral-700">
        <div class="container mx-auto py-2 lg:px-32 lg:pt-12">
            <label class="form-label">Drag to rearrange images</label>
            <VueDraggableNext class="grid grid-cols-12 gap-6 mt-5" :list="items" @change="getList" :move="checkMove">
                <div class="intro-y col-span-12 lg:col-span-4" v-for="item in items">
                    <div class="w-full p-1 md:p-2">
                        <div class="position-relative">
                            <button type="button"
                                class="btn btn-danger btn-sm position-absolute top-0 right-0"
                                v-html="bi_dash_square"
                                @click="deleteImage(item.img_id)">
                            </button>
                        </div>
                        <img alt="Left Banner"
                            class="block h-full w-full rounded-lg object-cover object-center"
                            :src="item.img" />
                    </div>
                </div>
            </VueDraggableNext>
            <div class="flex justify-end space-x-2">
                <button
                    @click="dragImgUpdate(props.directoryId, props.apiType, props.imgType)"
                    type="button" data-te-ripple-init
                    data-te-ripple-color="light"
                    class="inline-block rounded bg-primary px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg">
                    Update Rearrange Images
                </button>
            </div>
        </div>
    </section>
</template>
<style lang="scss" scoped>

</style>