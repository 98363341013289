import axios from "axios";
import URL_API from "./BaseUrl";
import store from "./../store";

const NotificationService = {
    NotificationListApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_notification_show`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    NotificationDropApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_notification_dropdown`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    NotificationSendApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_send_push_notification`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    NotificationLoadApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_load_push_notification`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    }
}

export default NotificationService;