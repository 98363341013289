import NotificationService from "@/services/NotificationService";
import store from "@/store";
import cookie from 'cookiejs';

const notification = {
    state: () => ({
        NOTIFICATIONSHOW: null,
        NOTIFICATIONDROP: null,
        NOTIFICATIONSEND: null
    }),
    getters: {
        NOTIFICATIONSHOW: (state) => {
            return state.NOTIFICATIONSHOW != null ? state.NOTIFICATIONSHOW : [];
        },
        NOTIFICATIONDROP: (state) => {
            return state.NOTIFICATIONDROP != null ? state.NOTIFICATIONDROP : [];
        },
        NOTIFICATIONSEND: (state) => {
            return state.NOTIFICATIONSEND != null ? state.NOTIFICATIONSEND : [];
        }
    },
    mutations: {
        NOTIFICATION_SHOW: (state, data) => {
            state.NOTIFICATIONSHOW = data
        },
        NOTIFICATION_DROPDOWN: (state, data) => {
            state.NOTIFICATIONDROP = data
        },
        NOTIFICATION_SEND: (state, data) => {
            state.NOTIFICATIONSEND = data
        }
    },
    actions: {
        NotificationCallList: async ({ commit }, json) => {
            const response = await NotificationService.NotificationListApi(json);
            if (response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("NOTIFICATION_SHOW", response);
            }
        },
        NotificationDropdown: async ({ commit }, json) => {
            const response = await NotificationService.NotificationDropApi(json);
            if (response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("NOTIFICATION_DROPDOWN", response);
            }
        },
        NotificationSend: async ({ commit }, json) => {
            const response = await NotificationService.NotificationSendApi(json);
            if (response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("NOTIFICATION_SEND", response);
            }
        }
    }
}

export default notification;