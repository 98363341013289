import SettingsService from '@/services/SettingsService';
import store from "@/store";
import cookie from 'cookiejs';
const Settings = {
    state: () => ({
        SCDL: null,
        CL: null,
        SL: null,
        CITY: null,
        SDL: null,
        CDL: null,
        SDLALL: null,
        meta: null,
        EXTPAGE: null,
        LOG: null
    }),
    getters: {
        SCDL: (state) => {
            return state.SCDL !== null ? state.SCDL : [];
        },
        CL: (state) => {
            return state.CL !== null ? state.CL : [];
        },
        SL: (state) => {
            return state.SL !== null ? state.SL : [];
        },
        CITY: (state) => {
            return state.CITY !== null ? state.CITY : [];
        },
        SDL: (state) => {
            return state.SDL !== null ? state.SDL : [];
        },
        SDLALL: (state) => {
            return state.SDLALL !== null ? state.SDLALL : [];
        },
        CDL: (state) => {
            return state.CDL !== null ? state.CDL : [];
        },
        meta: (state) => {
            return state.meta !== null ? state.meta : {}
        },
        EXTPAGE: (state) => {
            return state.EXTPAGE !== null ? state.EXTPAGE : {}
        },
        LOG: (state) => {
            return state.LOG !== null ? state.LOG : {}
        }
    },
    mutations: {
        COUNTRIE_DROP_LIST: (state, data) => {
            state.SCDL = data
        },
        COUNTRIE_LIST: (state, data) => {
            state.CL = data
        },
        STATES_LIST: (state, data) => {
            state.SL = data
        },
        STATES_DROP_LIST: (state, data) => {
            state.SDL = data
        },
        STATES_DROP_LIST_ALL: (state, data) => {
            state.SDLALL = data
        },
        CITIES_LIST: (state, data) => {
            state.CITY = data
        },
        CITIES_DROP_LIST: (state, data) => {
            state.CDL = data
        },
        META_GLOBAL: (state, data) => {
            state.meta = data
        },
        OTHER_PAGE_DATA: (state, data) => {
            state.EXTPAGE = data
        },
        ADMIN_LOG: (state, data) => {
            state.LOG = data
        }
    },
    actions: {
        SettingsCountrieDropList: async ({commit}) => {
            let response = await SettingsService.countrieDropList();
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("COUNTRIE_DROP_LIST", response);
            }
        },
        SettingsCountrieList: async ({commit}, json) => {
            let response = await SettingsService.countrieList(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("COUNTRIE_LIST", response);
            }
        },
        SettingsCountrieUpdate: async ({commit}, json) => {
            let response = await SettingsService.countrieUpdate(json);
            response.status == true ? store.dispatch("getSuccess", response.message) : store.dispatch("getError", response.message);
        },
        SettingsStatesList: async ({commit}, json) => {
            let response = await SettingsService.statesList(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("STATES_LIST", response);
            }
        },
        SettingsStatesDropList: async ({commit}, json) => {
            let response = await SettingsService.statesDropList(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("STATES_DROP_LIST", response);
            }
        },
        SettingsStatesDropListAll: async ({commit}) => {
            let response = await SettingsService.statesDropListAll();
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("STATES_DROP_LIST_ALL", response);
            }
        },
        SettingsStatesAdd: async ({commit}, json) => {
            let response = await SettingsService.statesAdd(json);
            response.status == true ? store.dispatch("getSuccess", response.message) : store.dispatch("getError", response.message);
        },
        SettingsCitiesList: async ({commit}, json) => {
            let response = await SettingsService.citiesList(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CITIES_LIST", response);
            }
        },
        SettingCitiesUpdate: async ({commit}, json) => {
            let response = await SettingsService.citiesUpdate(json);
            response.status == true ? store.dispatch("getSuccess", response.message) : store.dispatch("getError", response.message);
        },
        SettingsCityAdd: async ({commit}, json) => {
            let response = await SettingsService.citiesAdd(json)
            response.status == true ? store.dispatch("getSuccess", response.message) : store.dispatch("getError", response.message);
        },
        SettingsCityDropList: async ({commit}, json) => {
            let response = await SettingsService.citiesDropList(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CITIES_DROP_LIST", response);
            }
        },
        SettingsGlobalMeta: async ({commit}, json) => {
            let response = await SettingsService.metaGlobalApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("META_GLOBAL", response);
            }
        },
        SettingsOtherPage: async ({commit}, json) => {
            let response = await SettingsService.otherPageData(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("OTHER_PAGE_DATA", response);
            }
        },
        SettingsLog: async ({commit}, json) => {
            let response = await SettingsService.settingsAdminLog(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ADMIN_LOG", response);
            }
        },
        SettingsNewsLetter: async({ commit }, json) => {
            const response = await SettingsService.settingsNewsLetter(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                console.log("ANNOUNCEMENT_SHOW")
                // commit("ANNOUNCEMENT_SHOW", response);
            }
        }
    }
}

export default Settings;