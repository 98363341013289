const Swal = require("sweetalert2");

const toastify = {
  actions: {
    getError({ commit }, msg) {
      Swal.fire({
        icon: "error",
        title: `${msg}`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    getSuccess({ commit }, msg) {
      Swal.fire({
        icon: "success",
        title: `${msg}`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    getSuccessFull({ commit }, msg) {
      // Swal.fire({
      //   icon: "success",
      //   title: `${msg}`,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 3000,
      //   timerProgressBar: true,
      // });
      Swal.fire({
        icon: "success",
        title: `${msg}`,
      })
    },
  },
};

export default toastify;
