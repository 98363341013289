import OthersPageService from "@/services/OthersPageService";
import { data } from "jquery";
import cookie from 'cookiejs';
const OthersPage = {
    state: () => ({
        OTHERSPAGESLIST: null,
        OTHERSPAGESADD: null,
        OTHERSLIST: null,
        ADDOTHERS: null,
        OTHERSBANNER: null
    }),
    getters: {
        OTHERSPAGESLIST: (state) => {
            return state.OTHERSPAGESLIST != null ? state.OTHERSPAGESLIST : {}
        },
        OTHERSPAGESADD: (state) => {
            return state.OTHERSPAGESADD != null ? state.OTHERSPAGESADD : {}
        },
        OTHERSLIST: (state) => {
            return state.OTHERSLIST != null ? state.OTHERSLIST : {}
        },
        ADDOTHERS: (state) => {
            return state.ADDOTHERS != null ? state.ADDOTHERS : {}
        },
        OTHERSBANNER: (state) => {
            return state.OTHERSBANNER != null ? state.OTHERSBANNER : {}
        }
    },
    mutations: {
        OTHERS_PAGES_LIST: (state, data) => {
            state.OTHERSPAGESLIST = data;
        },
        OTHERS_PAGES_ADD: (state, data) => {
            state.OTHERSPAGESADD = data;
        },
        OTHERS_LIST: (state, data) => {
            state.OTHERSLIST = data
        },
        ADD_OTHERS: (state, data) => {
            state.ADDOTHERS = data
        },
        OTHERS_BANNER: (state, data) => {
            state.OTHERSBANNER = data
        }
    },
    actions: {
        OthersPageCallList: async ({ commit }, json) => {
            const response = await OthersPageService.OthersPageServiceApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("OTHERS_PAGES_LIST", response);
            }
        },
        OthersPageCallAdd: async ({ commit }, json) => {
            const response = await OthersPageService.OthersPageServiceApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("OTHERS_PAGES_ADD", response);
            }
        },
        OthersListCall: async ({ commit }, json) => {
            const response = await OthersPageService.OthersServiceApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("OTHERS_LIST", response);
            }
        },
        AddOthersListCall: async ({ commit }, json) => {
            const response = await OthersPageService.OthersServiceApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ADD_OTHERS", response);
            }
        },
        OthersBannerCall: async ({ commit }, json) => {
            const response = await OthersPageService.OthersBannerServiceApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("OTHERS_BANNER", response);
            }
        }
    }
}

export default OthersPage;