<script setup>
    import { ref, defineProps, defineExpose, computed } from "vue";
    const props = defineProps(['title']);
    const file = ref([]);
    function onChange(e) {
        let fileData = e.target.files;
        for (let i = 0; i < fileData.length; i++) {
            let reader = new FileReader();
            reader.readAsDataURL(fileData[i]);
            reader.onload = e => {
                file.value.push(e.target.result);
            };
        }
        // let fileData = e.target.files[0];
        // let reader = new FileReader();
        // reader.readAsDataURL(fileData);
        // reader.onload = e => {
        //     file.value = e.target.result;
        // };
    }
    defineExpose({
        file
    })
</script>
<template>
    <div>
        <label class="form-label">{{ props.title }}</label>
        <div class="input_container">
            <input type="file" multiple accept="application/pdf,application/vnd.ms-excel" class="form-control fileinput" @change="onChange($event)" />
        </div>
    </div>
</template>