import NewsService from "@/services/NewsService";
import store from "@/store";
import cookie from 'cookiejs';
const news = {
    state: () => ({
        NEWSBANNER: null,
        NEWSSHOW: null,
        NEWSDATA: null
    }),
    getters: {
        NEWSBANNER: (state) => {
            return state.NEWSBANNER != null ? state.NEWSBANNER : []
        },
        NEWSSHOW: (state) => {
            return state.NEWSSHOW != null ? state.NEWSSHOW : {}
        },
        NEWSDATA: (state) => {
            return state.NEWSDATA != null ? state.NEWSDATA : {}
        }
    },
    mutations: {
        NEWS_BANNER: (state, data) => {
            state.NEWSBANNER = data;
        },
        NEWS_SHOW: (state, data) => {
            state.NEWSSHOW = data;
        },
        NEWS_DATA: (state, data) => {
            state.NEWSDATA = data;
        }
    },
    actions: {
        NewsBannerCall: async ({ commit }, json) => {
            const response = await NewsService.NewsBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("NEWS_BANNER", response);
            }
        },
        NewsCallList: async ({ commit }, json) => {
            const response = await NewsService.NewsAdminApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("NEWS_SHOW", response);
            }
        },
        NewsDataSubmit: async ({ commit }, json) => {
            const response = await NewsService.NewsAdminApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("NEWS_DATA", response);
            }
        },
    }
}

export default news;