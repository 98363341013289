import HoroscopeService from "@/services/HoroscopeService";
import store from "@/store";
import cookie from 'cookiejs';
const horoscope = {
    state: () => ({
        HOROSCOPEBANNER: null,
        HOROSCOPESHOW: null,
        HOROSCOPEDATA: null
    }),
    getters: {
        HOROSCOPEBANNER: (state) => {
            return state.HOROSCOPEBANNER != null ? state.HOROSCOPEBANNER : []
        },
        HOROSCOPESHOW: (state) => {
            return state.HOROSCOPESHOW != null ? state.HOROSCOPESHOW : {}
        },
        HOROSCOPEDATA: (state) => {
            return state.HOROSCOPEDATA != null ? state.HOROSCOPEDATA : {}
        }
    },
    mutations: {
        HOROSCOPE_BANNER: (state, data) => {
            state.HOROSCOPEBANNER = data;
        },
        HOROSCOPE_SHOW: (state, data) => {
            state.HOROSCOPESHOW = data;
        },
        HOROSCOPE_DATA: (state, data) => {
            state.HOROSCOPEDATA = data;
        }
    },
    actions: {
        HoroscopeBannerCall: async({ commit }, json) => {
            const response = await HoroscopeService.HoroscopeBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOROSCOPE_BANNER", response);
            }
        },
        HoroscopeCall: async({ commit }, json) => {
            const response = await HoroscopeService.HoroscopeAdminApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOROSCOPE_SHOW", response);
            }
        },
        HoroscopeData: async({commit}, json) => {
            const response = await HoroscopeService.HoroscopeAdminApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOROSCOPE_DATA", response);
            }
        },
    }
}

export default horoscope;