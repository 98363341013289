import AnnouncementService from "@/services/AnnouncementService";
import store from "@/store";
import cookie from 'cookiejs';
const announcement = {
    state: () => ({
        ANNOUNCEMENTBANNER: null,
        ANNOUNCEMENTBUSINESSLIST: null,
        ANNOUNCEMENTDATA: null,
        ANNOUNCEMENTSHOW: null
    }),
    getters: {
        ANNOUNCEMENTBANNER: (state) => {
            return state.ANNOUNCEMENTBANNER != null ? state.ANNOUNCEMENTBANNER : [];
        },
        ANNOUNCEMENTBUSINESSLIST: (state) => {
            return state.ANNOUNCEMENTBUSINESSLIST != null ? state.ANNOUNCEMENTBUSINESSLIST : {};
        },
        EVENTSBUSINESSLIST: (state) => {
            const onData = [{value: 0, label: 'No Business', paid: 'paid', address: ''}];
            const children = [...new Set(onData.concat(state.ANNOUNCEMENTBUSINESSLIST.data))];
            return state.ANNOUNCEMENTBUSINESSLIST != null ? children : [];
        },
        ANNOUNCEMENTDATA: (state) => {
            return state.ANNOUNCEMENTDATA != null ? state.ANNOUNCEMENTDATA : {};
        },
        ANNOUNCEMENTSHOW: (state) => {
            return state.ANNOUNCEMENTSHOW != null ? state.ANNOUNCEMENTSHOW : {};
        }
    },
    mutations: {
        ANNOUNCEMENT_BANNER: (state, data) => {
            state.ANNOUNCEMENTBANNER = data;
        },
        ANNOUNCEMENT_BUSINESS_LIST: (state, data) => {
            state.ANNOUNCEMENTBUSINESSLIST = data;
        },
        ANNOUNCEMENT_DATA: (state, data) => {
            state.ANNOUNCEMENTDATA = data;
        },
        ANNOUNCEMENT_SHOW: (state, data) => {
            state.ANNOUNCEMENTSHOW = data;
        }
    },
    actions: {
        AnnouncementBannerCall: async({ commit }, json) => {
            const response = await AnnouncementService.announcementBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ANNOUNCEMENT_BANNER", response);
            }
        },
        AnnouncementBusinessList: async({ commit }) => {
            const response = await AnnouncementService.announcementBusinessListApi();
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ANNOUNCEMENT_BUSINESS_LIST", response);
            }
        },
        AnnouncementData: async({ commit }, json) => {
            const response = await AnnouncementService.announcementDataApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ANNOUNCEMENT_DATA", response);
            }
        },
        AnnouncementShow: async({ commit }, json) => {
            const response = await AnnouncementService.announcementDataApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ANNOUNCEMENT_SHOW", response);
            }
        }
    }
}

export default announcement