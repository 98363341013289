import EventsService from "@/services/EventsService";
import store from "@/store";
import cookie from 'cookiejs';
const events = {
    state: () => ({
        EVENTSBANNER: null,
        EVENTSCATEGORYLIST: null,
        EVENTSCATEGORYDROPDOWN: null,
        ADDEVENTSCATEGORY: null,
        EVENTSDATA: null,
        EVENTSSHOW: null
    }),
    getters: {
        EVENTSBANNER: (state) => {
            return state.EVENTSBANNER != null ? state.EVENTSBANNER : [];
        },
        EVENTSCATEGORYLIST: (state) => {
            return state.EVENTSCATEGORYLIST != null ? state.EVENTSCATEGORYLIST : [];
        },
        EVENTSCATEGORYDROPDOWN: (state) => {
            return state.EVENTSCATEGORYDROPDOWN != null ? state.EVENTSCATEGORYDROPDOWN : [];
        },
        ADDEVENTSCATEGORY: (state) => {
            return state.ADDEVENTSCATEGORY != null ? state.ADDEVENTSCATEGORY : {};
        },
        EVENTSDATA: (state) => {
            return state.EVENTSDATA != null ? state.EVENTSDATA : {};
        },
        EVENTSSHOW: (state) => {
            return state.EVENTSSHOW != null ? state.EVENTSSHOW : {};
        }
    },
    mutations: {
        EVENTS_BANNER: (state, data) => {
            state.EVENTSBANNER = data;
        },
        EVENTS_CATEGORY_LIST: (state, data) => {
            state.EVENTSCATEGORYLIST = data;
        },
        EVENTS_CATEGORY_DROPDOWN: (state, data) => {
            state.EVENTSCATEGORYDROPDOWN = data;
        },
        ADD_EVENTS_CATEGORY: (state, data) => {
            state.ADDEVENTSCATEGORY = data;
        },
        EVENTS_DATA: (state, data) => {
            state.EVENTSDATA = data;
        },
        EVENTS_SHOW: (state, data) => {
            state.EVENTSSHOW = data;
        }
    },
    actions: {
        EventsBannerCall: async({ commit }, json) => {
            const response = await EventsService.eventsBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("EVENTS_BANNER", response);
            }
        },
        EventsCategoryList: async({commit}, json) => {
            const response = await EventsService.eventsCategoryListApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("EVENTS_CATEGORY_LIST", response);
            }
        },
        EventsCategoryDropdown: async({commit}) => {
            const response = await EventsService.eventsCategoryDropdownApi();
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("EVENTS_CATEGORY_DROPDOWN", response);
            }
        },
        AddCategoryEvents: async({commit}, json) => {
            const response = await EventsService.eventsAddCategoryApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ADD_EVENTS_CATEGORY", response);
            }
        },
        EventsData: async({commit}, json) => {
            const response = await EventsService.eventsDataApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("EVENTS_DATA", response);
            }
        },
        EventsShow: async({ commit }, json) => {
            const response = await EventsService.eventsDataApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("EVENTS_SHOW", response);
            }
        }
    }
}
export default events;