import axios from "axios";
import URL_API from "./BaseUrl";
import store from "./../store";

const PropertyService = {
    PropertyBannerApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}property_banner`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    PropertyCategoryApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_property_category`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    PropertyApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_property`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
}

export default PropertyService;