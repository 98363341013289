import PromotionsService from "@/services/PromotionsService";
import store from "@/store";
import cookie from 'cookiejs';
const promotions = {
    state: () => ({
        PROMOTIONSBANNER: null,
        PROMOTIONSDATA: null,
        PROMOTIONSSHOW: null
    }),
    getters: {
        PROMOTIONSBANNER: (state) => {
            return state.PROMOTIONSBANNER != null ? state.PROMOTIONSBANNER : [];
        },
        PROMOTIONSDATA: (state) => {
            return state.PROMOTIONSDATA != null ? state.PROMOTIONSDATA : [];
        },
        PROMOTIONSSHOW: (state) => {
            return state.PROMOTIONSSHOW != null ? state.PROMOTIONSSHOW : [];
        },
    },
    mutations: {
        PROMOTIONS_BANNER: (state, data) => {
            state.PROMOTIONSBANNER =  data;
        },
        PROMOTIONS_DATA: (state, data) => {
            state.PROMOTIONSDATA =  data;
        },
        PROMOTIONS_SHOW: (state, data) => {
            state.PROMOTIONSSHOW =  data;
        },
    },
    actions: {
        PromotionsBannerCall: async({ commit }, json) => {
            const response = await PromotionsService.promotionsBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("PROMOTIONS_BANNER", response);
            }
        },
        PromotionsData: async({ commit }, json) => {
            const response = await PromotionsService.promotionsDataApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("PROMOTIONS_DATA", response);
            }
        },
        PromotionsShow: async({ commit }, json) => {
            const response = await PromotionsService.promotionsDataApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("PROMOTIONS_SHOW", response);
            }
        }
    }
}

export default promotions;