import CouponsService from "@/services/CouponsService";
import cookie from 'cookiejs';
const coupons = {
    state: () => ({
        COUPONSBANNER: null,
        COUPONSMARCHANT: null,
        COUPONSLIST: null
    }),
    getters: {
        COUPONSBANNER: (state) => {
            return state.COUPONSBANNER != null ? state.COUPONSBANNER : {}
        },
        COUPONSMARCHANT: (state) => {
            return state.COUPONSMARCHANT != null ? state.COUPONSMARCHANT : {}
        },
        COUPONSLIST: (state) => {
            return state.COUPONSLIST != null ? state.COUPONSLIST : {}
        }
    },
    mutations: {
        COUPONS_BANNER: (state, data) => {
            state.COUPONSBANNER = data
        },
        COUPONS_MARCHANT: (state, data) => {
            state.COUPONSMARCHANT = data
        },
        COUPONS_LIST: (state, data) => {
            state.COUPONSLIST = data
        }
    },
    actions: {
        CouponsBannerCall: async({ commit }, json) => {
            const response = await CouponsService.CouponsBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("COUPONS_BANNER", response);
            }
        },
        AdminCouponsMarchantCall: async({ commit }, json) => {
            const response = await CouponsService.AdminCouponsMarchantApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("COUPONS_MARCHANT", response);
            }
        },
        AdminCouponsListCall: async({ commit }, json) => {
            const response = await CouponsService.AdminCouponsApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("COUPONS_LIST", response);
            }
        },
    }
}

export default coupons;