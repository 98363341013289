const bi_arrow_clockwise = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
</svg>`

const bi_plus_square = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square text-light" viewBox="0 0 16 16">
<path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
</svg>`

const bi_dash_square = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
<path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>`

const bi_pencil_square = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
</svg>`

const bi_geo_alt_fill = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
<path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
</svg>`

const bi_toggle_on = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
<path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
</svg>`

const bi_toggle_off = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
<path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/>
</svg>`

const bi_x = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>`

const bi_arrow_right = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg>`

const bi_taxi_front_fill = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-car-front-fill" viewBox="0 0 16 16">
<path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z"/>
</svg>`

const bi_bounding_box_circles = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-bounding-box-circles" viewBox="0 0 16 16">
<path d="M2 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM0 2a2 2 0 0 1 3.937-.5h8.126A2 2 0 1 1 14.5 3.937v8.126a2 2 0 1 1-2.437 2.437H3.937A2 2 0 1 1 1.5 12.063V3.937A2 2 0 0 1 0 2zm2.5 1.937v8.126c.703.18 1.256.734 1.437 1.437h8.126a2.004 2.004 0 0 1 1.437-1.437V3.937A2.004 2.004 0 0 1 12.063 2.5H3.937A2.004 2.004 0 0 1 2.5 3.937zM14 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM2 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
</svg>`

const bi_star_fill = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>`

const bi_star = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
<path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
</svg>`

const bi_envelope  = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
</svg>`

const bi_annoncements = `<svg fill="currentColor" height="32" width="32" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
viewBox="0 0 292.518 292.518" xml:space="preserve"><g><path d="M292.518,125.66c0-17.848-13.592-32.578-30.965-34.381V18.455c0-2.476-1.222-4.791-3.265-6.189
   c-2.041-1.397-4.644-1.697-6.951-0.802L108.39,66.988H30.225c-1.989,0-3.897,0.791-5.305,2.197
   c-0.573,0.574-14.118,14.282-20.992,34.914c-6.502,19.513-8.138,48.891,20.993,78.031c1.406,1.407,3.314,2.197,5.304,2.197h1.736
   l59.188,93.738c1.374,2.177,3.769,3.496,6.342,3.496h61.382c0.008,0.001,0.016,0.001,0.02,0c4.143,0,7.5-3.357,7.5-7.5
   c0-1.655-0.536-3.186-1.445-4.427l-52.984-83.917l139.37,54.226c0.88,0.343,1.802,0.511,2.719,0.511c1.491,0,2.97-0.444,4.235-1.31
   c2.044-1.397,3.266-3.714,3.266-6.19v-72.92C278.927,158.233,292.518,143.506,292.518,125.66z M33.516,81.988h68.78v87.34H33.401
   C-3.445,130.018,25.215,91.55,33.516,81.988z M101.627,266.562l-51.913-82.215h43.643l51.911,82.215H101.627z M117.297,79.621
   l129.257-50.207v192.575l-129.257-50.291V79.621z M261.554,144.877v-38.44c9.071,1.694,15.965,9.662,15.965,19.223
   C277.518,135.219,270.625,143.183,261.554,144.877z"/><path d="M229.991,133.205h1c4.143,0,7.5-3.357,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-1c-4.143,0-7.5,3.357-7.5,7.5
   C222.491,129.848,225.848,133.205,229.991,133.205z"/><path d="M200.991,133.205h1c4.143,0,7.5-3.357,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-1c-4.143,0-7.5,3.357-7.5,7.5
   C193.491,129.848,196.848,133.205,200.991,133.205z"/><path d="M171.991,133.205h1c4.143,0,7.5-3.357,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-1c-4.143,0-7.5,3.357-7.5,7.5
   C164.491,129.848,167.848,133.205,171.991,133.205z"/><path d="M142.991,133.205h1c4.143,0,7.5-3.357,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-1c-4.143,0-7.5,3.357-7.5,7.5
   C135.491,129.848,138.848,133.205,142.991,133.205z"/></g></svg>`

const bi_businesses = `<svg fill="currentColor" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
viewBox="0 0 512 512" xml:space="preserve"><g><g><g><path d="M487.083,94.827L448,36.203V21.333C448,9.557,438.464,0,426.667,0H85.333C73.536,0,64,9.557,64,21.333v14.869
           L24.917,94.827c-2.325,3.499-3.584,7.637-3.584,11.84v42.667c0,18.837,8.341,35.627,21.333,47.36v293.973
           C42.667,502.443,52.203,512,64,512h384c11.797,0,21.333-9.557,21.333-21.333V196.693c12.992-11.733,21.333-28.523,21.333-47.36
           v-42.667C490.667,102.464,489.408,98.325,487.083,94.827z M384,405.333c0,11.776-9.536,21.333-21.333,21.333H149.333
           c-11.797,0-21.333-9.557-21.333-21.333v-128c0-11.776,9.536-21.333,21.333-21.333h213.333c11.797,0,21.333,9.557,21.333,21.333
           V405.333z M448,149.333c0,8.107-4.715,14.955-11.413,18.56c-1.195,0.64-2.411,1.109-3.627,1.493
           c-1.472,0.469-2.987,0.789-4.587,0.939c-0.725,0.064-1.472,0.256-2.197,0.235c-2.347-0.043-4.544-0.576-6.635-1.344
           c-0.853-0.299-1.685-0.704-2.496-1.109c-1.387-0.725-2.667-1.621-3.883-2.624c-0.832-0.704-1.643-1.429-2.389-2.283
           c-1.216-1.387-2.261-2.901-3.072-4.565c-0.363-0.683-0.64-1.408-0.917-2.133c-0.811-2.261-1.451-4.629-1.451-7.168v-42.667
           c0-11.776-9.536-21.333-21.333-21.333c-11.797,0-21.333,9.557-21.333,21.333v42.667c0,11.755-9.557,21.333-21.333,21.333
           S320,161.088,320,149.333v-42.667c0-11.776-9.536-21.333-21.333-21.333c-11.797,0-21.333,9.557-21.333,21.333v42.667
           c0,11.755-9.557,21.333-21.333,21.333s-21.333-9.579-21.333-21.333v-42.667c0-11.776-9.536-21.333-21.333-21.333
           c-11.797,0-21.333,9.557-21.333,21.333v42.667c0,11.755-9.557,21.333-21.333,21.333s-21.333-9.579-21.333-21.333v-42.667
           c0-11.776-9.536-21.333-21.333-21.333c-11.797,0-21.333,9.557-21.333,21.333v42.667c0,2.539-0.64,4.885-1.451,7.147
           c-0.277,0.747-0.576,1.472-0.917,2.176c-0.811,1.621-1.835,3.115-3.029,4.48c-0.768,0.875-1.6,1.643-2.496,2.389
           c-1.195,0.981-2.432,1.856-3.819,2.56c-0.811,0.427-1.643,0.832-2.496,1.131c-2.112,0.768-4.309,1.301-6.656,1.344
           c-0.683,0.021-1.408-0.171-2.133-0.235c-1.621-0.128-3.157-0.469-4.651-0.939c-1.216-0.405-2.411-0.853-3.605-1.493
           C68.715,164.288,64,157.44,64,149.333v-36.203l39.083-58.624c2.325-3.499,3.584-7.637,3.584-11.84h298.667
           c0,4.203,1.259,8.341,3.584,11.84L448,113.131V149.333z"/><rect x="170.673" y="298.667" width="170.667" height="85.333"/></g></g></g></svg>`

const bi_promotion = `<svg fill="currentColor" width="32" height="32" viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg"><path d="M15.1,2a1.68,1.68,0,0,1,1.71,1.63V4.85a1.68,1.68,0,0,1-1.63,1.71H10A3.4,3.4,0,0,0,6.56,9.81V42a3.4,3.4,0,0,0,3.25,3.41H42a3.4,3.4,0,0,0,3.41-3.25V36.9a1.68,1.68,0,0,1,1.63-1.71h1.22A1.67,1.67,0,0,1,50,36.82v6.35A6.82,6.82,0,0,1,43.18,50H8.83A6.82,6.82,0,0,1,2,43.18H2V8.83A6.83,6.83,0,0,1,8.82,2H15.1Z" fill-rule="evenodd"/><path d="M38.11,21a2.23,2.23,0,1,0,2.25,2.23h0A2.22,2.22,0,0,0,38.14,21Z"/><path d="M27.49,12.76A2.23,2.23,0,1,0,29.72,15a2.22,2.22,0,0,0-2.23-2.23Z"/><path d="M49.1,16.87l-1.87-2.24a3.94,3.94,0,0,1-.93-2.31l-.22-2.86a3.66,3.66,0,0,0-3.35-3.41l-2.49-.2a5.3,5.3,0,0,1-3-1.28L35.35,2.91a3.68,3.68,0,0,0-4.79-.05L28.5,4.6a4.72,4.72,0,0,1-2.7,1.1l-2.67.18a3.69,3.69,0,0,0-3.42,3.36l-.19,2.44a5.28,5.28,0,0,1-1.29,3L16.6,16.59a3.67,3.67,0,0,0,0,4.78l1.77,2.14a4.42,4.42,0,0,1,1,2.54l.2,2.75a3.68,3.68,0,0,0,3.35,3.42l2.5.22a5.17,5.17,0,0,1,3,1.27l1.9,1.64a3.7,3.7,0,0,0,4.79,0l2.18-1.82a4.08,4.08,0,0,1,2.43-1l2.85-.21A3.68,3.68,0,0,0,46,29l.2-2.31a5.93,5.93,0,0,1,1.43-3.32l1.52-1.73A3.66,3.66,0,0,0,49.1,16.87ZM23.38,15a4.15,4.15,0,1,1,4.15,4.14h0A4.13,4.13,0,0,1,23.36,15v0Zm6,12.22a.39.39,0,0,1-.25.17H27.89a.33.33,0,0,1-.26-.16.29.29,0,0,1,0-.31L36.28,11a.4.4,0,0,1,.26-.16h1.29a.31.31,0,0,1,.15.42h0Zm12.94-4a4.15,4.15,0,1,1-4.17-4.12h0a4.13,4.13,0,0,1,4.12,4.15h0Z"/></svg>`

const bi_reload = `<svg fill="#000000" height="18" width="18" version="1.1" id="Layer_1" viewBox="0 0 383.748 383.748" xml:space="preserve">
<g>
	<path d="M62.772,95.042C90.904,54.899,137.496,30,187.343,30c83.743,0,151.874,68.13,151.874,151.874h30
		C369.217,81.588,287.629,0,187.343,0c-35.038,0-69.061,9.989-98.391,28.888C70.368,40.862,54.245,56.032,41.221,73.593
		L2.081,34.641v113.365h113.91L62.772,95.042z"/>
	<path d="M381.667,235.742h-113.91l53.219,52.965c-28.132,40.142-74.724,65.042-124.571,65.042
		c-83.744,0-151.874-68.13-151.874-151.874h-30c0,100.286,81.588,181.874,181.874,181.874c35.038,0,69.062-9.989,98.391-28.888
		c18.584-11.975,34.707-27.145,47.731-44.706l39.139,38.952V235.742z"/>
</g>
</svg>`

export { bi_arrow_clockwise, bi_plus_square, bi_dash_square, bi_pencil_square, bi_geo_alt_fill, bi_toggle_on, bi_toggle_off, bi_x, bi_arrow_right, bi_taxi_front_fill, bi_bounding_box_circles, bi_star, bi_star_fill, bi_envelope, bi_promotion, bi_businesses, bi_annoncements, bi_reload };