<script setup>
    import { ref, defineProps, defineExpose, computed } from "vue";
    import { bi_dash_square } from "@/services/icon";
    const img = ref("");
    const fileInputKey = ref(0);
    const props = defineProps(['title']);
    // const emit = defineEmits(['change']);   
    const imageUpload = (e) => {
        let image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
            img.value = e.target.result;
        };
    }
    const deleteUpload = () => {
        img.value = "";
        fileInputKey.value++
    }
    defineExpose({
        img
    })
    
</script>
<template>
    <div>
        <label class="form-label">{{ props.title }}</label>
        <div class="input_container">
            <input class="form-control" accept="image/png, image/gif, image/jpeg" type="file" @change="imageUpload($event)" :key="fileInputKey"/>
            <div style="display: inline-block; margin: 10px;" v-if="img">
                <div class="position-relative">
                    <button type="button" class="btn btn-danger btn-sm position-absolute top-0 right-0" v-html="bi_dash_square" @click="deleteUpload"></button>
                </div>
                <img :src="img" class="preview" style="width:150px;"/>
            </div>
        </div>
    </div>
</template>