import PropertyService from "@/services/PropertyService";
import store from "@/store";
import { data } from "jquery";
import cookie from 'cookiejs';
const property = {
    state: () => ({
        PROPERTYBANNER: null,
        PROPERTYCATEGORY: null,
        PROPERTYCATEGORYSHOW: null,
        PROPERTYSHOW: null,
        ADDPROPERTY: null,
        PROPERTYDATA: null
    }),
    getters: {
        PROPERTYBANNER: (state) => {
            return state.PROPERTYBANNER != null ? state.PROPERTYBANNER : []
        },
        PROPERTYCATEGORY: (state) => {
            return state.PROPERTYCATEGORY != null ? state.PROPERTYCATEGORY : []
        },
        PROPERTYCATEGORYSHOW: (state) => {
            return state.PROPERTYCATEGORYSHOW != null ? state.PROPERTYCATEGORYSHOW : []
        },
        PROPERTYSHOW: (state) => {
            return state.PROPERTYSHOW != null ? state.PROPERTYSHOW : [];
        },
        ADDPROPERTY: (state) => {
            return state.ADDPROPERTY != null ? state.ADDPROPERTY : {};
        },
        PROPERTYDATA: (state) => {
            return state.PROPERTYDATA != null ? state.PROPERTYDATA : {};
        }
    },
    mutations: {
        PROPERTY_BANNER: (state, data) => {
            state.PROPERTYBANNER = data;
        },
        PROPERTY_CATEGORY: (state, data) => {
            state.PROPERTYCATEGORY = data;
        },
        PROPERTY_CATEGORY_SHOW: (state, data) => {
            state.PROPERTYCATEGORYSHOW = data;
        },
        PROPERTY_SHOW: (state, data) => {
            state.PROPERTYSHOW = data;
        },
        PROPERTY_CATEGORY_DROPDOWN: (state, data) => {
            state.PROPERTYCATEGORYDROPDOWN = data
        },
        ADD_PROPERTY: (state, data) => {
            state.ADDPROPERTY = data
        },
        PROPERTY_DATA: (state, data) => {
            state.PROPERTYDATA = data
        }
    },
    actions: {
        PropertyBannerCall: async ({ commit }, json) => {
            const response = await PropertyService.PropertyBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("PROPERTY_BANNER", response);
            }
        },
        PropertyCategoryCall: async ({ commit }, json) => {
            const response = await PropertyService.PropertyCategoryApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("PROPERTY_CATEGORY", response);
            }
        },
        PropertyCategoryShow: async ({ commit }, json) => {
            const response = await PropertyService.PropertyCategoryApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("PROPERTY_CATEGORY_SHOW", response);
            }
        },
        PropertyShow: async ({ commit }, json) => {
            const response = await PropertyService.PropertyApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("PROPERTY_SHOW", response);
            }
        },
        AddPropertyCall: async ({ commit }, json) => {
            const response = await PropertyService.PropertyApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ADD_PROPERTY", response);
            }
        },
        PropertyData: async ({ commit }, json) => {
            const response = await PropertyService.PropertyApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("PROPERTY_DATA", response);
            }
        },
    }
}

export default property;