<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex'
const store = useStore();
onMounted(() => {

})
</script>
<template>
  <div class="py-5 padding-header">
    <router-view />
  </div>
</template>
