import TenderService from "@/services/TenderService";
import store from "@/store";
import cookie from 'cookiejs';
const tender = {
    state: () => ({
        TENDERSBANNER: null,
        TENDERSCATEGORYLIST: null,
        TENDERSCATEGORYDROPDOWN: null,
        ADDTENDERSCATEGORY: null,
        TENDERSDATA: null,
        TENDERSSHOW: null,
        ADDTENDERSEMAIL: null
    }),
    getters: {
        TENDERSBANNER: (state) => {
            return state.TENDERSBANNER != null ? state.TENDERSBANNER : [];
        },
        TENDERSCATEGORYLIST: (state) => {
            return state.TENDERSCATEGORYLIST != null ? state.TENDERSCATEGORYLIST : [];
        },
        TENDERSCATEGORYDROPDOWN: (state) => {
            return state.TENDERSCATEGORYDROPDOWN != null ? state.TENDERSCATEGORYDROPDOWN : {};
        },
        ADDTENDERSCATEGORY: (state) => {
            return state.ADDTENDERSCATEGORY != null ? state.ADDTENDERSCATEGORY : {}
        },
        TENDERSDATA: (state) => {
            return state.TENDERSDATA != null ? state.TENDERSDATA : {}
        },
        TENDERSSHOW: (state) => {
            return state.TENDERSSHOW != null ? state.TENDERSSHOW : {}
        },
        ADDTENDERSEMAIL: (state) => {
            return state.ADDTENDERSEMAIL != null ? state.ADDTENDERSEMAIL : {}
        }
    },
    mutations: {
        TENDERS_BANNER: (state, data) => {
            state.TENDERSBANNER = data;
        },
        TENDERS_CATEGORY_LIST: (state, data) => {
            state.TENDERSCATEGORYLIST = data;
        },
        TENDERS_CATEGORY_DROPDOWN: (state, data) => {
            state.TENDERSCATEGORYDROPDOWN = data
        },
        ADD_TENDERS_CATEGORY: (state, data) => {
            state.ADDTENDERSCATEGORY = data
        },
        TENDERS_DATA: (state, data) => {
            state.TENDERSDATA = data
        },
        TENDERS_SHOW: (state, data) => {
            state.TENDERSSHOW = data
        },
        ADD_TENDERS_EMAIL: (state, data) => {
            state.ADDTENDERSEMAIL = data
        }
    },
    actions: {
        TendersBannerCall: async({ commit }, json) => {
            const response = await TenderService.TendersBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("TENDERS_BANNER", response);
            }
        },
        TendersCategoryList: async ({ commit }, json) => {
            const response = await TenderService.TendersCategoryListApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("TENDERS_CATEGORY_LIST", response);
            }
        },
        TendersCategoryDropdown: async ({ commit }) => {
            const response = await TenderService.TendersCategoryDropdownApi();
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("TENDERS_CATEGORY_DROPDOWN", response);
            }
        },
        AddCategoryTenders: async ({ commit }, json) => {
            const response = await TenderService.TendersAddCategoryApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ADD_TENDERS_CATEGORY", response);
            }
        },
        TendersData: async ({ commit }, json) => {
            const response = await TenderService.TendersDataApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("TENDERS_DATA", response);
            }
        },
        TendersShow: async ({ commit }, json) => {
            const response = await TenderService.TendersDataApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("TENDERS_SHOW", response);
            }
        },
        AddTenderEmail: async ({ commit }, json) => {
            const response = await TenderService.TendersEmailLoadApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ADD_TENDERS_EMAIL", response);
            }
        }
    }
}

export default tender;