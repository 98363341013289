import axios from "axios";
import URL_API from "./BaseUrl";
import store from "./../store";

const CarsService = {
    CarsBannerApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}cars_banner`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    CarsTypeApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_cars_type`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    CarsModelApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_cars_model`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    CarsBraindApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_cars_braind`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    CarsGearboxApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_cars_gearbox`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    CarsDropdownApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_cars_dropdown`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    CarsAddApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}admin_cars`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
}

export default CarsService;