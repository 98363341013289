import CarsService from "@/services/CarsService";
import store from "@/store";
import cookie from 'cookiejs';
const cars = {
    state: () => ({
        CARSBANNER: null,
        CARSTYPE: null,
        CARSTYPESHOW: null,
        CARSMODEL: null,
        CARSMODELSHOW: null,
        CARSBRAIND: null,
        CARSBRAINDSHOW: null,
        CARSGEARBOX: null,
        CARSGEARBOXSHOW: null,
        CARSDROPDOWNSHOW: null,
        CARSCALL: null,
        CARSSHOW: null
    }),
    getters: {
        CARSBANNER: (state) => {
            return state.CARSBANNER !== null ? state.CARSBANNER : []
        },
        CARSTYPE: (state) => {
            return state.CARSTYPE !== null ? state.CARSTYPE : {}
        },
        CARSTYPESHOW: (state) => {
            return state.CARSTYPESHOW !== null ? state.CARSTYPESHOW : {}
        },
        CARSMODEL: (state) => {
            return state.CARSMODEL !== null ? state.CARSMODEL : {}
        },
        CARSMODELSHOW: (state) => {
            return state.CARSMODELSHOW !== null ? state.CARSMODELSHOW : {}
        },
        CARSBRAIND: (state) => {
            return state.CARSBRAIND !== null ? state.CARSBRAIND : {}
        },
        CARSBRAINDSHOW: (state) => {
            return state.CARSBRAINDSHOW !== null ? state.CARSBRAINDSHOW : {}
        },
        CARSGEARBOX: (state) => {
            return state.CARSGEARBOX !== null ? state.CARSGEARBOX : {}
        },
        CARSGEARBOXSHOW: (state) => {
            return state.CARSGEARBOXSHOW !== null ? state.CARSGEARBOXSHOW : {}
        },
        CARSDROPDOWNSHOW: (state) => {
            return state.CARSDROPDOWNSHOW !== null ? state.CARSDROPDOWNSHOW : {}
        },
        CARSCALL: (state) => {
            return state.CARSCALL !== null ? state.CARSCALL : {}
        },
        CARSSHOW: (state) => {
            return state.CARSSHOW !== null ? state.CARSSHOW : {}
        }
    },
    mutations: {
        CARS_BANNER: (state, data) => {
            state.CARSBANNER = data;
        },
        CARS_TYPE: (state, data) => {
            state.CARSTYPE = data;
        },
        CARS_TYPE_SHOW: (state, data) => {
            state.CARSTYPESHOW = data;
        },
        CARS_MODEL: (state, data) => {
            state.CARSMODEL = data;
        },
        CARS_MODEL_SHOW: (state, data) => {
            state.CARSMODELSHOW = data;
        },
        CARS_BRAIND: (state, data) => {
            state.CARSBRAIND = data;
        },
        CARS_BRAIND_SHOW: (state, data) => {
            state.CARSBRAINDSHOW = data;
        },
        CARS_GEARBOX: (state, data) => {
            state.CARSGEARBOX = data;
        },
        CARS_GEARBOX_SHOW: (state, data) => {
            state.CARSGEARBOXSHOW = data;
        },
        CARS_DROPDOWN_SHOW: (state, data) => {
            state.CARSDROPDOWNSHOW = data;
        },
        CARS_CALL: (state, data) => {
            state.CARSCALL = data;
        },
        CARS_SHOW: (state, data) => {
            state.CARSSHOW = data;
        }
    },
    actions: {
        CarsBannerCall: async ({ commit }, json) => {
            const response = await CarsService.CarsBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_BANNER", response);
            }
        },
        CarsTypeCall: async ({ commit }, json) => {
            const response = await CarsService.CarsTypeApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_TYPE", response);
            }
        },
        CarsTypeShow: async ({ commit }, json) => {
            const response = await CarsService.CarsTypeApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_TYPE_SHOW", response);
            }
        },
        CarsModelCall: async ({ commit }, json) => {
            const response = await CarsService.CarsModelApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_MODEL", response);
            }
        },
        CarsModelShow: async ({ commit }, json) => {
            const response = await CarsService.CarsModelApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_MODEL_SHOW", response);
            }
        },
        CarsBraindCall: async ({ commit }, json) => {
            const response = await CarsService.CarsBraindApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_BRAIND", response);
            }
        },
        CarsBraindShow: async ({ commit }, json) => {
            const response = await CarsService.CarsBraindApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_BRAIND_SHOW", response);
            }
        },
        CarsGearboxCall: async ({ commit }, json) => {
            const response = await CarsService.CarsGearboxApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_GEARBOX", response);
            }
        },
        CarsGearboxShow: async ({ commit }, json) => {
            const response = await CarsService.CarsGearboxApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_GEARBOX_SHOW", response);
            }
        },
        CarsDropdownShow: async ({ commit }, json) => {
            const response = await CarsService.CarsDropdownApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_DROPDOWN_SHOW", response);
            }
        },
        CarsAddCall: async ({ commit }, json) => {
            const response = await CarsService.CarsAddApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_CALL", response);
            }
        },
        CarsShow: async ({ commit }, json) => {
            const response = await CarsService.CarsAddApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("CARS_SHOW", response);
            }
        }
    }
}

export default cars;