import axios from "axios";
import URL_API from "./BaseUrl";
import store from "./../store";

const CouponsService = {
    CouponsBannerApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}coupons_banner`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    AdminCouponsMarchantApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}coupons_marchant`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    AdminCouponsApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}coupons_list`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
}

export default CouponsService;