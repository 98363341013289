<script setup>
import { ref, defineProps, defineExpose, computed } from "vue";
import { bi_dash_square } from "@/services/icon";
import watermark from "watermarkjs";
const images = ref([]);
const props = defineProps(['title']);
const fileInputKey = ref(0);
const logo = require('@/assets/images/moncongo-watermark.png');
// const emit = defineEmits(['change']);   
const imageUpload = (e) => {
    let selectedFiles = e.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFiles[i]);
        reader.onload = e => {
            watermark([e.target.result, logo]).image(watermark.image.center(0.9)).then(img => images.value.push(img.src));
        };
    }
}
const deleteUpload = (image, key) => {
    images.value.splice(key, 1);
    if (key == 0) {
        fileInputKey.value++
    }
    // applyImage();
}
const applyImage = () => {
    for (let i = 0; i < images.value.length; i++) {
        let reader = new FileReader();
        reader.onload = (e) => {
            this.$refs.image[i].src = reader.result;
        };
        reader.readAsDataURL(images.value[i]);
    }
}
defineExpose({
    images
})
</script>
<template>
    <div>
        <label class="form-label">{{ props.title }}</label>
        <div class="input_container">
            <input class="form-control" multiple accept="image/png, image/gif, image/jpeg" type="file" @change="imageUpload"
                :key="fileInputKey" />
            <div style="display: inline-block; margin: 10px;" v-for="(image, key) in images" :key="key">
                <div class="position-relative">
                    <button type="button" class="btn btn-danger btn-sm position-absolute top-0 right-0"
                        v-html="bi_dash_square" @click="deleteUpload(image, key)"></button>
                </div>
                <img :src="image" class="preview" style="width:150px;" />
            </div>
        </div>
    </div>
</template>