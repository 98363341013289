import axios from "axios";
import URL_API from "./BaseUrl";
import store from "./../store";

const AuthService = {
  login(json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json" 
    }
    return axios({
      url: `${URL_API}admin_login`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  logout() {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization": store.getters.isLoggedIn
     }
    return axios({
      url: `${URL_API}admin_logout`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList
    }).then(async (response) => {
      return response.data;
    });
  },
  customersListApi(json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization": store.getters.isLoggedIn
     }
    return axios({
      url: `${URL_API}admin_customers`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  geoApi(json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json"
     }
    return axios({
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlug=${json.lat},${json.long}&key=AIzaSyDbX0dLs5QU4_7dWbzX_Qe6Kk5BE65VXVE`,
      method: "GET",
      clearCacheEntry: false,
      headers: headersList
    }).then(async (response) => {
      return response.data;
    });
  }
};

export default AuthService;
