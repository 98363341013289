<template>
  <div class="col-span-12 mt-6">
    <!-- <div class="intro-y block sm:flex items-center h-10">
      
    </div> -->
    <div class="grid grid-cols-12">
      <div class="col-span-6">
        <h2 class="text-lg font-medium truncate mr-5" v-if="title">
          {{ title }}
        </h2>
      </div>
      <div class="col-span-6">
        <!-- <div class="input-group lg:w-4">
          <div class="input-group-text">
            <b-icon-search class="w-4 h-5" />
          </div>
          <input type="text" class="form-control" placeholder="Search" />
        </div> -->
      </div>
    </div>
    <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
      <div
        v-if="isLoading"
        class="vtl-loading-mask flex items-center justify-center"
      >
        <rotate-square2></rotate-square2>
        <!-- <circle10></circle10> -->
        <!-- <div class="vtl-loading-content">
          <span style="color: white">Loading...</span>
        </div>
        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div> -->
      </div>
      <table class="table table-report sm:mt-2" ref="localTable">
        <thead>
          <tr>
            <th class="whitespace-nowrap" v-if="hasCheckbox">
              <div>
                <input type="checkbox" v-model="setting.isCheckAll" />
              </div>
            </th>
            <th
              class="whitespace-nowrap"
              v-for="(col, index) in columns"
              :class="col.headerClasses"
              :key="index"
              :style="
                Object.assign(
                  { width: col.width ? col.width : 'auto' },
                  col.headerStyles
                )
              "
            >
              <div
                :class="{
                  'vtl-sortable': col.sortable,
                  'vtl-both': col.sortable,
                  'vtl-asc':
                    setting.order === col.field && setting.sort === 'asc',
                  'vtl-desc':
                    setting.order === col.field && setting.sort === 'desc',
                }"
                @click="col.sortable ? doSort(col.field) : false"
              >
                {{ col.label }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="rows.length > 0">
          <template v-if="isStaticMode">
            <tr
             
              v-for="(row, i) in localRows"
              :key="i"
              :class="
                typeof rowClasses === 'function' ? rowClasses(row) : rowClasses
              "
            >
              <td v-if="hasCheckbox">
                <div>
                  <input
                    type="checkbox"
                    class="vtl-tbody-checkbox"
                    :ref="
                      (el) => {
                        rowCheckbox[i] = el;
                      }
                    "
                    :value="row[setting.keyColumn]"
                    @click="checked"
                  />
                </div>
              </td>
              <td
                v-for="(col, j) in columns"
                :key="j"
                :class="col.columnClasses"
                :style="col.columnStyles"
              >
                <div v-if="col.display" v-html="col.display(row)"></div>
                <template v-else>
                  <div v-if="setting.isSlotMode && slots[col.field]">
                    <slot :name="col.field" :value="row"></slot>
                  </div>
                  <span v-else>{{ row[col.field] }}</span>
                </template>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr
              v-for="(row, i) in rows"
              :key="i"
              :class="
                typeof rowClasses === 'function' ? rowClasses(row) : rowClasses
              "
            >
              <td v-if="hasCheckbox" class="vtl-tbody-td">
                <div>
                  <input
                    type="checkbox"
                    :ref="
                      (el) => {
                        rowCheckbox[i] = el;
                      }
                    "
                    :value="row[setting.keyColumn]"
                    @click="checked"
                  />
                </div>
              </td>
              <td
                v-for="(col, j) in columns"
                :key="j"
                :class="col.columnClasses"
                :style="col.columnStyles"
              >
                <div v-if="col.display" v-html="col.display(row)"></div>
                <div v-else>
                  <div v-if="setting.isSlotMode && slots[col.field]">
                    <slot :name="col.field" :value="row"></slot>
                  </div>
                  <span v-else>{{ row[col.field] }}</span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div
      class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3"
      v-if="rows.length > 0"
    >
      <template v-if="!setting.isHidePaging">
        <nav class="w-full sm:w-auto sm:mr-auto">
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: setting.page <= 1 }">
              <a
                class="page-link"
                href="javascript:void(0)"
                @click="setting.page = 1"
              >
                <b-icon-chevron-double-left class="w-4 h-5" />
              </a>
            </li>
            <li class="page-item" :class="{ disabled: setting.page <= 1 }">
              <a class="page-link" href="javascript:void(0)" @click="prevPage">
                <b-icon-chevron-left class="w-4 h-5" />
              </a>
            </li>
            <li
              class="page-item active"
              v-for="n in setting.paging"
              :key="n"
              :class="{ disabled: setting.page === n }"
            >
              <a
                class="page-link"
                href="javascript:void(0)"
                @click="movePage(n)"
                >{{ n }}</a
              >
            </li>
            <li
              class="page-item"
              :class="{ disabled: setting.page >= setting.maxPage }"
            >
              <a class="page-link" href="javascript:void(0)" @click="nextPage">
                <b-icon-chevron-right class="w-4 h-5" />
              </a>
            </li>
            <li
              class="page-item"
              :class="{ disabled: setting.page >= setting.maxPage }"
            >
              <a
                class="page-link"
                href="javascript:void(0)"
                @click="setting.page = setting.maxPage"
              >
                <!-- <i class="w-4 h-4" data-feather="chevrons-right"></i> -->
                <b-icon-chevron-double-right class="w-4 h-5" />
              </a>
            </li>
          </ul>
        </nav>
        <nav class="w-full sm:w-auto sm:mr-auto">
          <div role="status" aria-live="polite">
            {{
              stringFormat(
                messages.pagingInfo,
                setting.offset,
                setting.limit,
                total
              )
            }}
          </div>
        </nav>
        <select
          class="w-20 form-select box mt-3 sm:mt-0"
          v-model="setting.pageSize"
        >
          <option
            v-for="pageOption in pageOptions"
            :value="pageOption.value"
            :key="pageOption.value"
          >
            {{ pageOption.text }}
          </option>
        </select>
      </template>
    </div>
    <div
      class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3"
      v-else
    >
      {{ messages.noDataAvailable }}
    </div>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  computed,
  watch,
  onBeforeUpdate,
  nextTick,
  onMounted,
} from "vue";
export default {
  name: "my-table",
  components: {},
  emits: ["return-checked-rows", "do-search", "is-finished", "get-now-page"],
  props: {
    // (is data loading)
    isLoading: {
      type: Boolean,
      require: true,
    },
    // (Whether to perform a re-query)
    isReSearch: {
      type: Boolean,
      require: true,
    },
    // Checkbox (Presence of Checkbox)
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    // Checkbox (Returns data type for checked of Checkbox)
    checkedReturnType: {
      type: String,
      default: "key",
    },
    //  (title)
    title: {
      type: String,
      default: "",
    },
    //  (Field)
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //  (data)
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //  (data row classes)
    rowClasses: {
      type: [Array, Function],
      default: () => {
        return [];
      },
    },
    //  (Display the number of items on one page)
    pageSize: {
      type: Number,
      default: 10,
    },
    //  (Total number of transactions)
    total: {
      type: Number,
      default: 100,
    },
    //  (Current page number)
    page: {
      type: Number,
      default: 1,
    },
    //  (Sort condition)
    sortable: {
      type: Object,
      default: () => {
        return {
          order: "id",
          sort: "asc",
        };
      },
    },
    //  (Display text)
    messages: {
      type: Object,
      default: () => {
        return {
          pagingInfo: "Showing {0}-{1} of {2}",
          pageSizeChangeLabel: "Row count:",
          gotoPageLabel: "Go to page:",
          noDataAvailable: "No data",
        };
      },
    },
    //  (Static mode(no refresh server data))
    isStaticMode: {
      type: Boolean,
      default: false,
    },
    //  (V-slot mode)
    isSlotMode: {
      type: Boolean,
      default: false,
    },
    //  (Hide paging)
    isHidePaging: {
      type: Boolean,
      default: false,
    },
    //  (Dropdown of Display the number of items on one page)
    pageOptions: {
      type: Array,
      default: () => [
        {
          value: 10,
          text: 10,
        },
        {
          value: 25,
          text: 25,
        },
        {
          value: 50,
          text: 50,
        },
      ],
    },
  },
  setup(props, { emit, slots }) {
    let localTable = ref(null);

    //  (Validate dropdown's values have page-size value or not)
    let defaultPageSize =
      props.pageOptions.length > 0
        ? ref(props.pageOptions[0].value)
        : ref(props.pageSize);
    if (props.pageOptions.length > 0) {
      props.pageOptions.forEach((v) => {
        if (
          Object.prototype.hasOwnProperty.call(v, "value") &&
          Object.prototype.hasOwnProperty.call(v, "text") &&
          props.pageSize == v.value
        ) {
          defaultPageSize.value = v.value;
        }
      });
    }

    //  (Internal set value for components)
    const setting = reactive({
      // Slot(Enable slot mode)
      isSlotMode: props.isSlotMode,
      // (Whether to select all)
      isCheckAll: false,
      // (Hide paging)
      isHidePaging: props.isHidePaging,
      // KEY (KEY field name)
      keyColumn: computed(() => {
        let key = "";
        Object.assign(props.columns).forEach((col) => {
          if (col.isKey) {
            key = col.field;
          }
        });
        return key;
      }),
      // (current page number)
      page: props.page,
      // (Display count per page)
      pageSize: defaultPageSize.value,
      // (Maximum number of pages)
      maxPage: computed(() => {
        if (props.total <= 0) {
          return 0;
        }
        let maxPage = Math.floor(props.total / setting.pageSize);
        let mod = props.total % setting.pageSize;
        if (mod > 0) {
          maxPage++;
        }
        return maxPage;
      }),
      // (The starting value of the page number)
      offset: computed(() => {
        return (setting.page - 1) * setting.pageSize + 1;
      }),
      // (Maximum number of pages0
      limit: computed(() => {
        let limit = setting.page * setting.pageSize;
        return props.total >= limit ? limit : props.total;
      }),
      // (Paging array)
      paging: computed(() => {
        let startPage = setting.page - 2 <= 0 ? 1 : setting.page - 2;
        if (setting.maxPage - setting.page <= 2) {
          startPage = setting.maxPage - 4;
        }
        startPage = startPage <= 0 ? 1 : startPage;
        let pages = [];
        for (let i = startPage; i <= setting.maxPage; i++) {
          if (pages.length < 5) {
            pages.push(i);
          }
        }
        return pages;
      }),
      // (Sortable for local)
      order: props.sortable.order,
      sort: props.sortable.sort,
      pageOptions: props.pageOptions,
    });

    // (Data rows for local)
    const localRows = computed(() => {
      // sort rows
      let property = setting.order;
      let sort_order = 1;
      if (setting.sort === "desc") {
        sort_order = -1;
      }
      let rows = props.rows;
      rows.sort((a, b) => {
        if (a[property] < b[property]) {
          return -1 * sort_order;
        } else if (a[property] > b[property]) {
          return sort_order;
        } else {
          return 0;
        }
      });

      // return sorted and offset rows
      let result = [];
      for (let index = setting.offset - 1; index < setting.limit; index++) {
        if (rows[index]) {
          result.push(rows[index]);
        }
      }

      nextTick(function () {
        //
        callIsFinished();
      });

      return result;
    });

    ////////////////////////////
    //
    //  Checkbox
    //  (Checkbox related operations)
    //

    // Checkbox (Define Checkbox reference)
    const rowCheckbox = ref([]);
    if (props.hasCheckbox) {
      /**
       * (Execute before re-rendering)
       */
      onBeforeUpdate(() => {
        // (Clear all values before each update)
        rowCheckbox.value = [];
      });

      /**
       * Checkbox (Check all checkboxes for monitoring)
       */
      watch(
        () => setting.isCheckAll,
        (state) => {
          let isChecked = [];
          rowCheckbox.value.forEach((val, i) => {
            if (val) {
              val.checked = state;
              if (val.checked) {
                if (props.checkedReturnType == "row") {
                  isChecked.push(localRows.value[i]);
                } else {
                  isChecked.push(val.value);
                }
              }
            }
          });
          // (Return the selected data on the screen)
          emit("return-checked-rows", isChecked);
        }
      );
    }

    /**
     * Checkbox (Checkbox click event)
     */
    const checked = () => {
      let isChecked = [];
      rowCheckbox.value.forEach((val, i) => {
        if (val && val.checked) {
          if (props.checkedReturnType == "row") {
            isChecked.push(localRows.value[i]);
          } else {
            isChecked.push(val.value);
          }
        }
      });
      // (Return the selected data on the screen)
      emit("return-checked-rows", isChecked);
    };

    /**
     * (Clear all selected data on the screen)
     */
    const clearChecked = () => {
      rowCheckbox.value.forEach((val) => {
        if (val && val.checked) {
          val.checked = false;
        }
      });
      // (Return the selected data on the screen)
      emit("return-checked-rows", []);
    };

    ////////////////////////////
    //
    //  (Sorting, page change, etc. related operations)
    //

    /**
     * (Call execution sequencing)
     */
    const doSort = (order) => {
      let sort = "asc";
      if (order == setting.order) {
        // (When sorting items)
        if (setting.sort == "asc") {
          sort = "desc";
        }
      }
      let offset = (setting.page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      setting.order = order;
      setting.sort = sort;
      emit("do-search", offset, limit, order, sort);

      // (Clear the selected data on the screen)
      if (setting.isCheckAll) {
        // (It will be cleared when you cancel all selections)
        setting.isCheckAll = false;
      } else {
        if (props.hasCheckbox) {
          clearChecked();
        }
      }
    };

    // /**
    //  * (Switch page number)
    //  *
    //  * @param page      number  (New page number)
    //  * @param prevPage  number  (Current page number)
    //  */
    const changePage = (page, prevPage) => {
      setting.isCheckAll = false;
      let order = setting.order;
      let sort = setting.sort;
      let offset = (page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      if (!props.isReSearch || page > 1 || page == prevPage) {
        // (Call query will only be executed if the page number is changed without re-query)
        emit("do-search", offset, limit, order, sort);
      }
    };
    // (Monitor page switching)
    watch(() => setting.page, changePage);
    // (Monitor manual page switching)
    watch(
      () => props.page,
      (val) => {
        if (val <= 1) {
          setting.page = 1;
          emit("get-now-page", setting.page);
        } else if (val >= setting.maxPage) {
          setting.page = setting.maxPage;
          emit("get-now-page", setting.page);
        } else {
          setting.page = val;
        }
      }
    );

    /**
     * (Switch display number)
     */
    const changePageSize = () => {
      if (setting.page === 1) {
        // changePage()
        changePage(setting.page, setting.page);
      } else {
        // changePage()
        setting.page = 1;
        setting.isCheckAll = false;
      }
    };
    // (Monitor display number switch)
    watch(() => setting.pageSize, changePageSize);

    /**
     * (Previous page)
     */
    const prevPage = () => {
      if (setting.page == 1) {
        // (If it is the first page, it will not be executed)
        return false;
      }
      setting.page--;
    };

    /**
     * (Move to the specified number of pages)
     */
    const movePage = (page) => {
      setting.page = page;
    };

    /**
     * (Next page)
     */
    const nextPage = () => {
      if (setting.page >= setting.maxPage) {
        // (If it is equal to or greater than the maximum number of pages, no execution)
        return false;
      }
      setting.page++;
    };

    // (Monitoring data changes)
    watch(
      () => props.rows,
      () => {
        if (props.isReSearch || props.isStaticMode) {
          setting.page = 1;
        }
        nextTick(function () {
          // (Return the private components after the data is rendered)
          if (!props.isStaticMode) {
            callIsFinished();
          }
        });
      }
    );

    const stringFormat = (template, ...args) => {
      return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
      });
    };

    // Call 「is-finished」 Method
    const callIsFinished = () => {
      if (localTable.value) {
        let localElement =
          localTable.value.getElementsByClassName("is-rows-el");
        emit("is-finished", localElement);
      }
      emit("get-now-page", setting.page);
    };

    /**
     * (Mounted Event)
     */
    onMounted(() => {
      nextTick(() => {
        if (props.rows.length > 0) {
          callIsFinished();
        }
      });
    });

    if (props.hasCheckbox) {
      // Checkbox (When Checkbox is needed)
      return {
        slots,
        localTable,
        localRows,
        setting,
        rowCheckbox,
        checked,
        doSort,
        prevPage,
        movePage,
        nextPage,
        stringFormat,
      };
    } else {
      return {
        slots,
        localTable,
        localRows,
        setting,
        doSort,
        prevPage,
        movePage,
        nextPage,
        stringFormat,
      };
    }
  },
};
</script>
<style lang="scss" scoped>
.vtl-sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px !important;
}
.vtl-both {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
}
.vtl-asc {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==");
}
.vtl-desc {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=");
}
.page-item.active.disabled {
  .page-link {
    background-color: rgb(47, 164, 255);
    color: #fff;
  }
}
.page-item {
  .page-link {
    background-color: rgb(255, 255, 255);
  }
}
.page-item.disabled {
  .page-link {
    background-color: rgb(218, 218, 218);
  }
}
.vtl-loading-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(47, 164, 255, 0.5);
  display: flex;
  flex-flow: column;
  transition: opacity 0.3s ease;
}
.vtl-loading-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
