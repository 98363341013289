import JobsService from "@/services/JobsService";
import store from "@/store";
import cookie from 'cookiejs';
const jobs = {
    state: () => ({
        JOBSBANNER: null,
        JOBSCATEGORYLIST: null,
        JOBSCATEGORYDROPDOWN: null,
        ADDJOBSCATEGORY: null,
        JOBSDATA: null,
        JOBSSHOW: null,
        JOBSFORM: null
    }),
    getters: {
        JOBSBANNER: (state) => {
            return state.JOBSBANNER != null ? state.JOBSBANNER : []
        },
        JOBSCATEGORYLIST: (state) => {
            return state.JOBSCATEGORYLIST != null ? state.JOBSCATEGORYLIST : [];
        },
        JOBSCATEGORYDROPDOWN: (state) => {
            return state.JOBSCATEGORYDROPDOWN != null ? state.JOBSCATEGORYDROPDOWN : [];
        },
        ADDJOBSCATEGORY: (state) => {
            return state.ADDJOBSCATEGORY != null ? state.ADDJOBSCATEGORY : {};
        },
        JOBSDATA: (state) => {
            return state.JOBSDATA != null ? state.JOBSDATA : {};
        },
        JOBSSHOW: (state) => {
            return state.JOBSSHOW != null ? state.JOBSSHOW : {};
        },
        JOBSFORM: (state) => {
            return state.JOBSFORM != null ? state.JOBSFORM : {};
        }
    },
    mutations: {
        JOBS_BANNER: (state, data) => {
            state.JOBSBANNER = data;
        },
        JOBS_CATEGORY_LIST: (state, data) => {
            state.JOBSCATEGORYLIST = data;
        },
        JOBS_CATEGORY_DROPDOWN: (state, data) => {
            state.JOBSCATEGORYDROPDOWN = data;
        },
        ADD_JOBS_CATEGORY: (state, data) => {
            state.ADDJOBSCATEGORY = data;
        },
        JOBS_DATA: (state, data) => {
            state.JOBSDATA = data;
        },
        JOBS_SHOW: (state, data) => {
            state.JOBSSHOW = data;
        },
        JOBS_FORM: (state, data) => {
            state.JOBSFORM = data;
        }
    },
    actions: {
        JobsBannerCall: async ({ commit }, json) => {
            const response = await JobsService.JobsBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("JOBS_BANNER", response);
            }
        },
        JobsCategoryList: async ({ commit }, json) => {
            const response = await JobsService.jobsCategoryListApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("JOBS_CATEGORY_LIST", response);
            }
        },
        JobsCategoryDropdown: async ({ commit }) => {
            const response = await JobsService.jobsCategoryDropdownApi();
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("JOBS_CATEGORY_DROPDOWN", response);
            }
        },
        AddCategoryJobs: async ({ commit }, json) => {
            const response = await JobsService.jobsAddCategoryApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ADD_JOBS_CATEGORY", response);
            }
        },
        JobsData: async ({ commit }, json) => {
            const response = await JobsService.jobsDataApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("JOBS_DATA", response);
            }
        },
        JobsShow: async ({ commit }, json) => {
            const response = await JobsService.jobsDataApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("JOBS_SHOW", response);
            }
        },
        JobsForm: async ({ commit }, json) => {
            const response = await JobsService.jobsFormApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("JOBS_FORM", response);
            }
        }
    }
}

export default jobs;