import { createStore } from "vuex";
import Axios from "axios";
// import createPersistedState from "vuex-persistedstate";
import cookie from "cookiejs";
import store from "@/store";
import Swal from "sweetalert2";

import homepage from "./HomePageStore";
import sweetalert from "./sweetalert";
import business from "./BusinessStore";
import announcement from "./AnnouncementStore";
import promotions from "./PromotionsStors";
import events from "./EventsStore";
import horoscope from "./HoroscopeStore";
import news from "./NewsStore";
import tender from "./TenderStore";
import property from "./PropertyStore";
import cars from "./CarsStore";
import jobs from "./JobsStore";
import Settings from "./SettingsStore";
import coupons from "./CouponsStore";
import OthersPage from "./OthersPageStore";
import notification from "./NotificationStore";
import AuthService from "@/services/AuthService.js";
import router from "../router";

const getDefaultState = () => {
  return {
    token: "",
    access: "",
    CUSTOMERSLIST: null,
  };
};

export default createStore({
  // strict: true,
  // plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: (state) => {
      return state.token ? state.token : cookie.get("logKey");
    },
    isAccess: (state) => {
      return state.access
        ? state.access
        : JSON.parse(cookie.get("menuKey"));
    },
    CUSTOMERSLIST: (state) => {
      return state.CUSTOMERSLIST ? state.CUSTOMERSLIST : {};
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      cookie.set("logKey", token);
      state.token = token;
    },
    SET_ACCESS: (state, access) => {
      cookie.set("menuKey", JSON.stringify(access));
      state.access = access;
    },
    RESET: (state) => {
      cookie.remove("logKey");
      cookie.remove("menuKey")
      // cookie.remove('firebaseKey');
      Object.assign(state, getDefaultState());
    },
    CUSTOMERS_LIST: (state, data) => {
      state.CUSTOMERSLIST = data;
    },
  },
  actions: {
    login: ({ commit, dispatch }, token) => {
      commit("SET_TOKEN", token);
      // set auth header
      Axios.defaults.headers.common["Authorization"] = token;
    },
    getAccess: ({ commit }, json) => {
      commit("SET_ACCESS", json);
    },
    logout: async ({ commit }) => {
      commit("RESET", "");
    },
    getAddressFrom: async ({ commit }, json) => {
      const response = await AuthService.geoApi(json);
    },
    CustomersListCall: async ({ commit }, json) => {
      const response = await AuthService.customersListApi(json);
      if (response.status === false) {
        cookie.remove("logKey");
        location.reload();
      } else {
        commit("CUSTOMERS_LIST", response);
      }
    },
    getSweetalertDelete: ({ commit }, json) => {
      Swal.fire({
        title: json.title,
        text: json.text,
        // showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: json.btn,
        // denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("", "Status updated successfully").then(() => {
            store.dispatch(json.storeType, json.data).then(() => {
              router.go(router.currentRoute);
              // if (store.state.parmition.status == true) {
              // } else {
              //   store.dispatch("getError", store.state.parmition.message);
              // }
            });
          });
        }
      });
    },
  },
  modules: {
    homepage,
    sweetalert,
    business,
    announcement,
    promotions,
    events,
    horoscope,
    news,
    tender,
    property,
    cars,
    jobs,
    coupons,
    Settings,
    OthersPage,
    notification,
  },
});
