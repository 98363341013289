import axios from "axios";
import URL_API from "./BaseUrl";
import store from "./../store";

const HomePageService = {
    homePageBannerApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}home_banner`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    },
    homePageListApi(json) {
        let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
            "Authorization": store.getters.isLoggedIn
        }
        return axios({
            url: `${URL_API}home_list`,
            method: "post",
            clearCacheEntry: false,
            headers: headersList,
            data: json
        }).then(async (response) => {
            return response.data;
        });
    }
}

export default HomePageService;