import HomePageService from "@/services/HomePageService";
import store from "@/store";
import { data } from "jquery";
import cookie from 'cookiejs';
const homepage = {
    state: () => ({
        HOMEFIRSTBANNER: null,
        HOMETWOBANNER: null,
        HOMETHREEBANNER: null,
        HOMEFOURBANNER: null,
        HOMEFIVEBANNER: null,
        HOMEFIRSTBANNERSUBMIT: null,

        HOMEFIRSTLISTSHOW: null,
        HOMESECOUNDLISTSHOW: null,
        HOMETHIRDLISTSHOW: null,
        HOMEFIRSTLISTSUBMIT: null,
    }),
    getters: {
        HOMEFIRSTBANNER: (state) => {
            return state.HOMEFIRSTBANNER !== null ? state.HOMEFIRSTBANNER : [];
        },
        HOMEFIRSTBANNERSUBMIT: (state) => {
            return state.HOMEFIRSTBANNERSUBMIT !== null ? state.HOMEFIRSTBANNERSUBMIT : {};
        },
        HOMETWOBANNER: (state) => {
            return state.HOMETWOBANNER !== null ? state.HOMETWOBANNER : [];
        },
        HOMETHREEBANNER: (state) => {
            return state.HOMETHREEBANNER !== null ? state.HOMETHREEBANNER : [];
        },
        HOMEFOURBANNER: (state) => {
            return state.HOMEFOURBANNER !== null ? state.HOMEFOURBANNER : [];
        },
        HOMEFIVEBANNER: (state) => {
            return state.HOMEFIVEBANNER !== null ? state.HOMEFIVEBANNER : [];
        },

        HOMEFIRSTLISTSUBMIT: (state) => {
            return state.HOMEFIRSTLISTSUBMIT !== null ? state.HOMEFIRSTLISTSUBMIT : [];
        },
        HOMEFIRSTLISTSHOW: (state) => {
            return state.HOMEFIRSTLISTSHOW !== null ? state.HOMEFIRSTLISTSHOW : {};
        },
        HOMESECOUNDLISTSHOW: (state) => {
            return state.HOMESECOUNDLISTSHOW !== null ? state.HOMESECOUNDLISTSHOW : {};
        },
        HOMETHIRDLISTSHOW: (state) => {
            return state.HOMETHIRDLISTSHOW !== null ? state.HOMETHIRDLISTSHOW : {};
        }
    },
    mutations: {
        HOME_FIRST_BANNER: (state, data) => {
            state.HOMEFIRSTBANNER = data;
        },
        HOME_TWO_BANNER: (state, data) => {
            state.HOMETWOBANNER = data;
        },
        HOME_THREE_BANNER: (state, data) => {
            state.HOMETHREEBANNER = data;
        },
        HOME_FOUR_BANNER: (state, data) => {
            state.HOMEFOURBANNER = data;
        },
        HOME_FIVE_BANNER: (state, data) => {
            state.HOMEFIVEBANNER = data;
        },
        HOME_FIRST_BANNER_SUBMIT: (state, data) => {
            state.HOMEFIRSTBANNERSUBMIT = data;
        },

        HOME_FIRST_LIST_SUBMIT: (state, data) => {
            state.HOMEFIRSTLISTSUBMIT = data;
        },
        HOME_FIRST_LIST_SHOW: (state, data) => {
            state.HOMEFIRSTLISTSHOW = data;
        },
        HOME_SECOUND_LIST_SHOW: (state, data) => {
            state.HOMESECOUNDLISTSHOW = data;
        },
        HOME_THIRD_LIST_SHOW: (state, data) => {
            state.HOMETHIRDLISTSHOW = data;
        }
    },
    actions: {
        HomeFirstBanner: async ({commit}, json) => {
            const response = await HomePageService.homePageBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOME_FIRST_BANNER", response);
            }
        },
        HomeTwoBanner: async ({commit}, json) => {
            const response = await HomePageService.homePageBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOME_TWO_BANNER", response);
            }
        },
        HomeThreeBanner: async ({commit}, json) => {
            const response = await HomePageService.homePageBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOME_THREE_BANNER", response);
            }
        },
        HomeFourBanner: async ({commit}, json) => {
            const response = await HomePageService.homePageBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOME_FOUR_BANNER", response);
            }
        },
        HomeFiveBanner: async ({commit}, json) => {
            const response = await HomePageService.homePageBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOME_FIVE_BANNER", response);
            }
        },
        HomeFirstAddBanner: async ({commit}, json) => {
            const response = await HomePageService.homePageBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOME_FIRST_BANNER_SUBMIT", response);
            }
        },

        HomeFirstShow: async ({commit}, json) => {
            const response = await HomePageService.homePageListApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOME_FIRST_LIST_SHOW", response);
            }
        },
        HomeSecondShow: async ({commit}, json) => {
            const response = await HomePageService.homePageListApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOME_SECOUND_LIST_SHOW", response);
            }
        },
        HomeThirdShow: async ({commit}, json) => {
            const response = await HomePageService.homePageListApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOME_THIRD_LIST_SHOW", response);
            }
        },
        HomeFirstAddList: async ({commit}, json) => {
            const response = await HomePageService.homePageListApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("HOME_FIRST_LIST_SUBMIT", response);
            }
        }
    }
}

export default homepage;